import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useCan,
  useNavigation,
  useUpdate,
} from '@pankod/refine-core';
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  List,
  MenuProps,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  TextField,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';

import {
  UserOutlined,
  EllipsisOutlined,
  QuestionCircleOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  SaveOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import { IPayment } from 'interfaces';
import React, { useContext, useState } from 'react';
import { Role } from '../../enums/role';
import { UserContext } from 'UserProvider';
import { firmColor } from 'utility/getColor';
import moment from 'moment';
import { PaymentStatus } from 'enums/payment.status';
import ModalSetData from 'components/payments/modalSetData';
import { useTranslation } from 'react-i18next';
import { Firms } from 'enums/firms.type';
import dayjs from 'dayjs';
import ModalInfo from 'components/modals/offerInfo';
import { saveList } from 'utility/exportExcel';
import { VamaType } from 'enums/vama.type';

export const TransportPayList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const { list } = useNavigation();
  const pageSizeOptions = Number(localStorage.getItem('pageSizeOptions')) || 10;

  const initialFilter: CrudFilters = [
    {
      value: [PaymentStatus.NEW, PaymentStatus.INWORK, PaymentStatus.SET_DATA],
      field: 'payments.status',
      operator: 'in',
    },
    {
      field: 'offer.createdAt',
      operator: 'gte',
      value: `${dayjs().format('YYYY')}-${dayjs().format('MM')}-01`,
    },
    {
      field: 'offer.createdAt',
      operator: 'lt',
      value: `${dayjs().add(1, 'months').format('YYYY')}-${dayjs()
        .add(1, 'months')
        .format('MM')}-01`,
    },
  ];

  const { mutate: update } = useUpdate();

  const canConfirm =
    useCan({ resource: 'payment_driver', action: 'confirm' }).data?.can ||
    false;

  const {
    tableProps,
    sorter,
    tableQueryResult: { refetch, data },
    setFilters,
    setPageSize,
    setCurrent,
  } = useTable<IPayment>({
    resource: 'payment/drivers',
    queryOptions: { retry: 5 },
    initialSorter: [
      {
        field: 'offer.uuid',
        order: 'desc',
      },
    ],
    initialPageSize: pageSizeOptions,
    initialFilter,
  });

  const { t } = useTranslation();
  const items = (value: any): MenuProps['items'] => {
    return [
      {
        label: 'Когда получили документы?',
        key: '1',
        icon: <FileDoneOutlined />,
        onClick: () => {
          setSelectedUuid(value.uuid);
          setOpen(true);
        },
      },
      { type: 'divider' },
      {
        label: 'Отправить на оплату',
        key: '2',
        icon: <FileDoneOutlined />,
        onClick: () => {
          update(
            {
              resource: 'payment',
              id: value.uuid,
              values: {
                status: PaymentStatus.INWORK,
              },
              successNotification: {
                description: t('update.titleSuccess'),
                message: t('update.textSuccess'),
                type: 'success',
              },
              errorNotification: {
                description: t('error.createText'),
                message: 'Возникла ошибка',
                type: 'error',
              },
            },
            {
              onSuccess: () => {
                refetch();
              },
            },
          );
        },
      },
      { type: 'divider' },
      {
        label: 'Подтвердить',
        key: '3',
        icon: <CheckCircleOutlined />,
        disabled: !canConfirm,
        onClick: () => {
          update(
            {
              resource: 'payment',
              id: value.uuid,
              values: {
                status: PaymentStatus.DONE,
              },
              successNotification: {
                description: t('update.titleSuccess'),
                message: t('update.textSuccess'),
                type: 'success',
              },
              errorNotification: {
                description: t('error.createText'),
                message: 'Возникла ошибка',
                type: 'error',
              },
            },
            {
              onSuccess: () => {
                refetch();
              },
            },
          );
        },
      },
    ];
  };

  const menuProps = (value: any) => {
    const itms = items(value);

    return { items: itms };
  };

  const [form] = Form.useForm();

  const changeFilters = (values: any) => {
    const data = form.getFieldsValue();

    const selectedFilters: CrudFilters = [];

    if (data.driver) {
      selectedFilters.push({
        field: 'offerLender',
        operator: 'contains',
        value: data.driver,
      });
    } else {
      selectedFilters.push({
        field: 'offerLender',
        operator: 'contains',
        value: null,
      });
    }
    if (data.offerNumber) {
      selectedFilters.push({
        field: 'offer.uuid',
        operator: 'eq',
        value: data.offerNumber,
      });
    } else {
      selectedFilters.push({
        field: 'offer.uuid',
        operator: 'eq',
        value: null,
      });
    }
    if (data.createdAt) {
      selectedFilters.push({
        field: 'offer.createdAt',
        operator: 'gte',
        value: data.createdAt
          ? `${dayjs(data.createdAt).format('YYYY')}-${dayjs(
              data.createdAt,
            ).format('MM')}-01`
          : '',
      });
      selectedFilters.push({
        field: 'offer.createdAt',
        operator: 'gte',
        value: data.createdAt
          ? `${dayjs(data.createdAt).format('YYYY')}-${dayjs(
              data.createdAt,
            ).format('MM')}-01`
          : '',
      });
    } else {
      selectedFilters.push({
        field: 'offer.createdAt',
        operator: 'gte',
        value: '',
      });
      selectedFilters.push({
        field: 'offer.createdAt',
        operator: 'lt',
        value: '',
      });
    }

    if (data.finished) {
      selectedFilters.push({
        field: 'payments.status',
        operator: 'in',
        value: [
          PaymentStatus.NEW,
          PaymentStatus.SET_DATA,
          PaymentStatus.INWORK,
          PaymentStatus.DONE,
        ],
      });
    } else if (data.in_work) {
      selectedFilters.push({
        field: 'payments.status',
        operator: 'in',
        value: [PaymentStatus.INWORK],
      });
    } else {
      selectedFilters.push({
        field: 'payments.status',
        operator: 'in',
        value: [
          PaymentStatus.NEW,
          PaymentStatus.INWORK,
          PaymentStatus.SET_DATA,
        ],
      });
    }

    if (data.company) {
      selectedFilters.push({
        field: 'offer.company',
        operator: 'eq',
        value: data.company,
      });
    } else {
      selectedFilters.push({
        field: 'offer.company',
        operator: 'eq',
        value: null,
      });
    }
    if (data.type) {
      selectedFilters.push({
        field: 'vamaDriver.vamaType',
        operator: 'eq',
        value: data.type,
      });
    } else {
      selectedFilters.push({
        field: 'vamaDriver.vamaType',
        operator: 'eq',
        value: null,
      });
    }

    setFilters(selectedFilters);
  };

  const [modalContextInfo, setModalContextInfo] = useState<boolean>(false);

  return (
    <>
      {selectedUuid && modalContextInfo && (
        <ModalInfo
          selectedUuid={Number(selectedUuid)}
          open={modalContextInfo}
          onClose={() => {
            setModalContextInfo(false);
            setSelectedUuid(null);
          }}
          refetch={refetch}
        />
      )}
      {selectedUuid && (
        <CanAccess resource="payments" action="setData">
          <ModalSetData
            open={open}
            close={() => setOpen(false)}
            selectedUuid={selectedUuid}
            refetch={refetch}
          />
        </CanAccess>
      )}

      <List
        headerProps={{
          extra: [
            me?.role &&
            data &&
            [
              Role.ACCOUNTANT,
              Role.ADMIN,
              Role.CONTROL,
              Role.DIRECTOR,
              Role.SYSTEM,
            ].includes(me.role) ? (
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => saveList(data)}
              >
                {t('buttons.download.report')}
              </Button>
            ) : (
              <></>
            ),
          ],
        }}
      >
        <Form
          form={form}
          initialValues={{
            createdAt: dayjs(),
            company: '',
            driver: '',
            type: '',
            offerNumber: '',
            finished: false,
            in_work: false,
          }}
          layout="vertical"
        >
          <Row gutter={[6, 2]}>
            <Col xs={12} md={4}>
              <Form.Item name="driver">
                <Input
                  placeholder="Перевозчик"
                  size="small"
                  onChange={(value) => {
                    form.setFieldsValue({
                      company: '',
                      type: '',
                      createdAt: null,
                      finished: true,
                    });
                    changeFilters(value);
                  }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={3}>
              <Form.Item name="offerNumber">
                <Input
                  placeholder="Заявка"
                  size="small"
                  allowClear
                  onChange={(value) => {
                    form.setFieldsValue({
                      company: '',
                      type: '',
                      createdAt: null,
                      finished: true,
                    });
                    changeFilters(value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="createdAt">
                <DatePicker
                  size="small"
                  format={'MMMM YYYY'}
                  picker="month"
                  onChange={changeFilters}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="company">
                <Select
                  size="small"
                  style={{ width: '200px' }}
                  onChange={changeFilters}
                  allowClear
                  defaultValue={''}
                  onClear={() => form.resetFields(['company'])}
                >
                  <Select.Option value={''} children={'Показать все'} />
                  <Select.Option value={Firms.REAL} children={Firms.REAL} />
                  <Select.Option value={Firms.RCI} children={Firms.RCI} />
                  <Select.Option
                    value={Firms.RCI_INVEST}
                    children={Firms.RCI_INVEST}
                  />
                  <Select.Option
                    value={Firms.RCI_PMR}
                    children={Firms.RCI_PMR}
                  />
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="type">
                <Select
                  allowClear
                  defaultValue={''}
                  onClear={() => form.resetFields(['type'])}
                  size="small"
                  style={{ width: '200px' }}
                  onChange={changeFilters}
                >
                  <Select.Option value={''} children={'Показать все типы'} />
                  <Select.Option
                    value={VamaType.EXPEDITION}
                    children={VamaType.EXPEDITION}
                  />
                  <Select.Option
                    value={VamaType.SHIP}
                    children={VamaType.SHIP}
                  />
                  <Select.Option
                    disabled
                    value={VamaType.AIRPLANE}
                    children={VamaType.AIRPLANE}
                  />
                </Select>
              </Form.Item>
            </Col>{' '}
            <Col>
              <Form.Item name="finished">
                <Switch
                  checkedChildren="Включены готовые"
                  unCheckedChildren="Готовые скрыты"
                  onChange={(value) => {
                    form.setFieldValue('in_work', false);
                    changeFilters(value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="in_work">
                <Switch
                  checkedChildren="В работе"
                  unCheckedChildren="Не растаможен"
                  onChange={(value) => {
                    form.setFieldValue('finished', false);
                    changeFilters(value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          {...tableProps}
          rowKey="uuid"
          pagination={{
            ...tableProps.pagination,
            position: ['bottomRight'],
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '200'],
            size: 'small',
          }}
          // onChange={(value) => {
          //   if (value.pageSize) {
          //     localStorage.setItem('pageSizeOptions', String(value.pageSize));
          //     setPageSize(value.pageSize);
          //   }
          //   if (value.current) {
          //     setCurrent(value.current);
          //   }
          // }}
          caption={
            <Typography.Text type="secondary" style={{ padding: '4px' }}>
              {`Всего оплат : ${data?.total}`}
            </Typography.Text>
          }
          scroll={{ x: 'max-content' }}
          size="small"
        >
          <Table.Column
            dataIndex="vamaDriver"
            key="vamaDriver"
            title="Перевозчик"
            align="center"
            render={(value) => {
              switch (value.vamaType) {
                case VamaType.EXPEDITION:
                  return <TextField value={value.offer.offerDriver.orgName} />;
                case VamaType.SHIP:
                  return <TextField value={value.ship.ship.orgName} />;
              }
            }}
          />
          <Table.Column
            dataIndex="vamaDriver"
            key="vamaDriver"
            title="Заявка"
            align="center"
            fixed="left"
            // render={(value) => (
            //   <Tooltip title={value.offer.company}>
            //     <Tag
            //       color={firmColor(value.offer.company)}
            //       style={{
            //         cursor: 'pointer',
            //         width: '50px',
            //         textAlign: 'center',
            //       }}
            //       onContextMenu={(e) => {
            //         e.preventDefault();
            //         console.log(value);
            //         setSelectedUuid(value.uuid);
            //         setModalContextInfo(true);
            //       }}
            //       onClick={() => {
            //         list(`blanks/offers?uuid=${value.offer.uuid}`);
            //       }}
            //     >
            //       {value.offer.offerNumber}
            //     </Tag>
            //   </Tooltip>
            // )}
            render={(value) => {
              switch (value.vamaType) {
                case VamaType.EXPEDITION:
                  return (
                    <Tooltip title={value.offer.company}>
                      <Tag
                        color={firmColor(value.offer.company)}
                        style={{
                          cursor: 'pointer',
                          width: '50px',
                          textAlign: 'center',
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          setSelectedUuid(value.uuid);
                          setModalContextInfo(true);
                        }}
                        onClick={() => {
                          list(`blanks/offers?uuid=${value.offer.uuid}`);
                        }}
                      >
                        {value.offer.offerNumber}
                      </Tag>
                    </Tooltip>
                  );
                case VamaType.SHIP:
                  return (
                    <Tooltip title={value.ship.company}>
                      <Tag
                        color={firmColor(value.ship.company)}
                        style={{
                          cursor: 'pointer',
                          textAlign: 'center',
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          setSelectedUuid(value.uuid);
                          setModalContextInfo(true);
                        }}
                        onClick={() => {
                          list(`blanks/ship?uuid=${value.ship.uuid}`);
                        }}
                      >
                        {`${value.ship.uuid}/${value.ship.rs}`}
                      </Tag>
                    </Tooltip>
                  );
              }
            }}
          />
          <Table.Column
            dataIndex="vamaDriver"
            key="vamaDriver"
            title="Сумма(лей)"
            align="right"
            render={(value) => {
              switch (value.vamaType) {
                case VamaType.EXPEDITION:
                  return (
                    <TextField
                      value={Number(
                        +value.cursDriver * +value.offer.priceDriver,
                      ).toLocaleString('ru-Ru', { maximumFractionDigits: 2 })}
                    />
                  );
                case VamaType.SHIP:
                  return (
                    <TextField
                      value={Number(
                        +value.cursDriver * +value.ship.priceBuy,
                      ).toLocaleString('ru-Ru', { maximumFractionDigits: 2 })}
                    />
                  );
              }
            }}
            // render={(value) => (
            //   <TextField
            //     value={Number(
            //       +value.cursDriver * +value.offer.priceDriver,
            //     ).toLocaleString('ru-Ru', {
            //       maximumFractionDigits: 2,
            //       minimumFractionDigits: 2,
            //     })}
            //   />
            // )}
          />
          {me?.role &&
            [Role.DIRECTOR, Role.ACCOUNTANT, Role.SECRETARY].includes(
              me?.role,
            ) && (
              <Table.Column
                dataIndex="datePayment"
                key="datePayment"
                title="Дата"
                align="center"
                render={(value, record: any) => (
                  <TextField
                    value={value ? moment(value).format('DD.MM.YYYY') : ''}
                  />
                )}
              />
            )}
          {me?.role &&
            [Role.DIRECTOR, Role.ACCOUNTANT, Role.SECRETARY].includes(
              me?.role,
            ) && (
              <Table.Column
                dataIndex="vamaDriver"
                key="vamaDriver"
                title="Опл. перевозчик"
                align="left"
                render={(value, record: any) => {
                  switch (value.vamaType) {
                    case VamaType.EXPEDITION:
                      return (
                        <TextField
                          value={`${moment(
                            record.datePayment
                              ? record.datePayment
                              : value.datePayment,
                          )
                            .add(+value.offer.driverFrom, 'days')
                            .format('DD.MM.YYYY')} ${
                            value.offer.driverFrom !== value.offer.driverTo
                              ? `- ${moment(
                                  record.datePayment
                                    ? record.datePayment
                                    : value.datePayment,
                                )
                                  .add(+value.offer.driverTo, 'days')
                                  .format('DD.MM.YYYY')}`
                              : ''
                          }`}
                        />
                      );
                    case VamaType.SHIP:
                      return <TextField value={'---'} />;
                  }
                }}
              />
            )}
          <Table.Column
            dataIndex="status"
            key="status"
            title="Статус"
            align="right"
            render={(value) => {
              switch (value) {
                case PaymentStatus.NEW:
                  return (
                    <Tag color="warning" icon={<QuestionCircleOutlined />}>
                      {value}
                    </Tag>
                  );
                case PaymentStatus.SET_DATA:
                  return (
                    <Tag color="purple" icon={<LoadingOutlined />}>
                      {value}
                    </Tag>
                  );
                case PaymentStatus.INWORK:
                  return (
                    <Tag color="blue" icon={<LoadingOutlined />}>
                      {value}
                    </Tag>
                  );
                case PaymentStatus.DONE:
                  return (
                    <Tag color="green" icon={<CheckCircleOutlined />}>
                      {value}
                    </Tag>
                  );
                default:
                  return <Tag icon={<UserOutlined />}>{value}</Tag>;
              }
            }}
          />
          <Table.Column
            dataIndex="user"
            key="user"
            title="RCI"
            width={80}
            align="center"
            render={(value) =>
              value && value.pictures?.path ? (
                <Tooltip
                  title={`${value.lastName} ${value.firstName}`}
                  placement="left"
                >
                  <Avatar
                    style={{ width: '50px', height: '50px' }}
                    src={`${process.env.REACT_APP_BACKEND_URL}${value.pictures.path}`}
                    alt={value.lastName}
                  />
                  {/* <TextField value={value.uuid} /> */}
                </Tooltip>
              ) : (
                <></>
              )
            }
          />

          <Table.Column
            key="action"
            title=""
            fixed="right"
            width={20}
            render={(_, value: any) => (
              <>
                <Dropdown
                  menu={menuProps(value)}
                  arrow
                  trigger={['click']}
                  placement="bottomLeft"
                  destroyPopupOnHide
                >
                  <Button
                    icon={<EllipsisOutlined />}
                    shape="circle"
                    type="dashed"
                  />
                </Dropdown>
              </>
            )}
          />
        </Table>
      </List>
    </>
  );
};
