import {
  Alert,
  Badge,
  BadgeProps,
  Button,
  Calendar,
  CalendarProps,
  Col,
  DatePicker,
  Flex,
  Form,
  Grid,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  theme,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import './styles.css';
import { finished } from 'stream';
import { ICalendar, IOffer, IRegistry, IUser } from '../../interfaces';
import { RegistryStatus } from '../../enums/registry.status';
import 'dayjs/locale/ru';
import { API_URL } from '../../App';
import { UserStatus } from '../../enums/user.status';
import { Role } from '../../enums/role';
import { useCustom } from '@pankod/refine-core';
import { FileAddOutlined, PhoneFilled } from '@ant-design/icons';

import {
  FilterDropdown,
  FilterDropdownProps,
  getDefaultSortOrder,
  TextField,
  useForm,
} from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ModalAddCalendar from 'components/calendar/addCalendar';
import { CalendarType } from 'enums/calendar.type';
import ModalViewCalendarRecord from 'components/calendar/modalViewCalendarRecord';

// Создайте пользовательскую локаль

const CalendarPayments = ({ me }: { me: IUser }) => {
  const { token } = theme.useToken();
  const screen = Grid.useBreakpoint();
  const { t } = useTranslation();
  const { data, refetch } = useCustom<ICalendar[]>({
    url: `${API_URL}/api/v1/calendar/get/calendar`,
    method: 'get',
  });

  const [value, setValue] = useState(() => dayjs());
  const [selectedMoneth, setSelectedMonth] = useState(() => dayjs());

  // const onSelect = (newValue: Dayjs) => {
  //   setSelectedValue(newValue);

  //   setOpenModal(true);
  // };

  const onPanelChange = (newValue: Dayjs) => {
    setValue(newValue);
  };

  const dateFullCellRender = (date: any, info: any) => {
    const dateObjs = data?.data.filter((dateObj) =>
      dayjs(dateObj.dateCall).isSame(date, 'day'),
    );

    const today = dayjs();
    const isSameMonth = date.isSame(selectedMoneth, 'month');
    const isPast = date.isBefore(today, 'day');
    const isFuture = date.isAfter(today, 'day');

    const isWeekend = () => {
      const dayW = dayjs(date).day(); // Получаем день недели (0 - воскресенье, 6 - суббота)
      return dayW === 0 || dayW === 6; // Проверяем, является ли день выходным
    };

    // if (!isSameMonth) {
    //   return;
    // }
    return (
      <Flex
        // onClick={() => onSelect(date)}
        style={{
          height: '200px',
          padding: 6,
          margin: 4,
          background: date.isSame(dayjs(), 'day')
            ? '#d7d7d7'
            : isSameMonth
            ? 'white'
            : '#d0d0d0',
          borderRadius: '8px',
          border: date.isSame(dayjs(), 'day')
            ? '1px solid #5cbdb520'
            : isWeekend()
            ? '1px solid #a71b8c24'
            : '1px solid rgba(129, 129, 129, 0.3)',
          boxShadow: date.isSame(dayjs(), 'day')
            ? '#69696999 0px 5px 20px 0px'
            : isWeekend()
            ? '#a71b8c8c 0px 5px 9px 0px'
            : '#69696999 0px 5px 10px 0px',
        }}
        vertical
        flex={1}
      >
        <Flex justify="left">
          <Typography.Text type={isWeekend() ? 'danger' : undefined}>
            {date.date()}
          </Typography.Text>
        </Flex>
        <Flex vertical style={{ height: 'calc(100% - 20px)' }}>
          <Flex vertical style={{ overflowY: 'scroll' }} flex={1}>
            {dateObjs?.map((dateObj, index) => {
              return (
                <Flex
                  key={index}
                  style={{ textAlign: 'left' }}
                  className="record"
                  onClick={() => {
                    setOpenModalEdit(true);
                    setSelectedUuid(dateObj.uuid);
                  }}
                >
                  <TextField
                    value={`${dateObjs.length > 1 ? `${+index + 1}) ` : ''}
                  ${dateObj.title ? dateObj.title : dateObj.text}`}
                  />
                </Flex>
              );
            })}
          </Flex>
          <Flex
            style={{ marginTop: 'auto', padding: '4px' }}
            justify={'center'}
            onClick={() => {
              form.setFieldValue('dateCall', date);
              setOpenModalAdd(true);
            }}
          >
            {isSameMonth && (
              <Button
                size="small"
                type="text"
                icon={<FileAddOutlined />}
                children={screen.md ? 'Добавить' : ''}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = useState<number>();

  const { formProps, saveButtonProps, queryResult, form } = useForm<ICalendar>({
    resource: 'calendar',
    redirect: false,
    onMutationSuccess: () => {
      setOpenModalAdd(false);
      form.resetFields();
      refetch();
    },
    successNotification: {
      description: t('calendar.update'),
      message: t('calendar.updateText'),
      type: 'success',
    },
    errorNotification: {
      description: 'Запись не создана',
      message: 'Возникла ошибка',
      type: 'error',
    },
  });

  return (
    <Flex vertical>
      {openModalEdit && (
        <ModalViewCalendarRecord
          open={openModalEdit}
          close={() => {
            setOpenModalEdit(false);
            refetch();
          }}
          uuid={selectedUuid}
          refetch={() => {
            setSelectedUuid(undefined);
            refetch();
          }}
        />
      )}

      {openModalAdd && (
        <Modal
          open={openModalAdd}
          destroyOnClose
          title={'Добавить запись '}
          onCancel={() => setOpenModalAdd(false)}
          okButtonProps={saveButtonProps}
        >
          <Form
            form={form}
            {...formProps}
            layout="vertical"
            initialValues={{ dateCall: null, title: '', text: '' }}
          >
            <Form.Item name="dateCall" rules={[{ required: true }]}>
              <DatePicker
                style={{ width: '100%' }}
                disabled
                format={'DD.MM.YYYY'}
              />
            </Form.Item>
            <Form.Item
              name="title"
              label="Заголовок"
              rules={[{ required: true }]}
            >
              <Input placeholder="Заголовок" />
            </Form.Item>
            <Form.Item name="text" label="Содержимое">
              <Input.TextArea placeholder="Текст записи" />
            </Form.Item>
            <Form.Item name="type" noStyle initialValue={CalendarType.SHIP}>
              <Input hidden />
            </Form.Item>
          </Form>
        </Modal>
      )}

      <Flex
        style={{
          padding: token.padding,
          backgroundColor: token.colorBgContainer,
        }}
      >
        <Calendar
          onChange={(value) => {
            setSelectedMonth(value);
          }}
          mode="month"
          defaultValue={dayjs()}
          // onSelect={onSelect}
          fullCellRender={dateFullCellRender}
          onPanelChange={onPanelChange}
        />
      </Flex>
    </Flex>
  );
};

export default CalendarPayments;
