import {
  Button,
  EmailField,
  Modal,
  Table,
  TagField,
  TextField,
  Tooltip,
} from '@pankod/refine-antd';
import { UserStatus } from 'enums/user.status';
import { IPayment, IUser } from 'interfaces';
import { SolutionOutlined } from '@ant-design/icons';

import { CSSProperties, useEffect, useState } from 'react';
import { useNavigation, useNotification, useOne } from '@pankod/refine-core';
import { axiosInstance } from 'App';
import { Badge, StatisticProps, TableColumnsType, Tag } from 'antd';
import moment from 'moment';
import CountUp from 'react-countup/build/CountUp';
import { firmColor } from 'utility/getColor';
import { useNavigate } from '@pankod/refine-react-router-v6';

export const ModalDebtors = ({
  uuid,
  open,
  cancel,
}: {
  uuid: number;
  open: boolean;
  cancel: () => void;
}) => {
  const { list } = useNavigation();
  const { open: notify } = useNotification();
  const { data: record, isLoading } = useOne<any>({
    resource: 'dashboard/admin',
    id: uuid,
    liveMode: 'auto',
  });

  const expandedRowRender = (value: any) => {
    const columns: TableColumnsType = [
      { title: 'Дата растаможки', dataIndex: 'dateVama', key: 'dateVama' },
      { title: 'Дата Оплаты', dataIndex: 'datePayment', key: 'datePayment' },
      {
        title: 'Номер заявки',
        dataIndex: 'offer',
        key: 'offer',
        render: (value) => (
          <Tooltip title={value.company}>
            <Tag
              color={firmColor(value.company)}
              style={{
                cursor: 'pointer',
                width: '50px',
                textAlign: 'center',
              }}
              onClick={() => {
                list(`blanks/offers?uuid=${value.uuid}`);
              }}
            >
              {value.offerNumber}
            </Tag>
          </Tooltip>
        ),
      },
      {
        title: 'Сумма задолженности',
        dataIndex: 'summPayment',
        key: 'summPayment',
        render: (value) => (
          <TextField
            value={Number(value).toLocaleString('ru-RU', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          />
        ),
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (value) => <Badge status="processing" text={value} />,
      },
    ];

    const data: any = value.data.map((el: IPayment, id: any) => {
      return {
        key: id,
        status: el.status,
        summPayment: `${el.vamaLender.offer.priceFirm} ${
          el.vamaLender.offer.currencyFirm
        } (${Number(
          +el.vamaLender.cursLender * +el.vamaLender.offer.priceFirm,
        ).toLocaleString('ru-RU', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })} MDL)`,
        dateVama: moment(el.vamaLender?.datePayment).format('DD.MM.YYYY'),
        datePayment:
          el?.vamaLender?.datePayment &&
          el?.vamaLender?.offer.firmFrom === el?.vamaLender?.offer.firmTo
            ? moment(el.vamaLender.datePayment)
                .add(el.vamaLender.offer.firmFrom, 'days')
                .format('DD.MM.YYYY')
            : `${moment(el?.vamaLender?.datePayment)
                .add(el.vamaLender?.offer.firmFrom, 'days')
                .format('DD.MM.YYYY')} - ${moment(el.vamaLender?.datePayment)
                .add(el.vamaLender?.offer.firmTo, 'days')
                .format('DD.MM.YYYY')}`,
        offer: el.vamaLender?.offer,
      };
    });

    return (
      <Table
        size="small"
        scroll={{ x: 'max-content' }}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    );
  };

  const columns: TableColumnsType = [
    { title: 'Компания', dataIndex: 'orgName', key: 'orgName' },
    {
      title: 'Сумма задолженности',
      dataIndex: 'summ',
      key: 'summ',
      render: (value) => (
        <TextField
          value={Number(value).toLocaleString('ru-RU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        />
      ),
    },
    {
      title: 'Количество заявок',
      dataIndex: 'data',
      key: 'data',
      render: (value, array) => <>{value.length}</>,
    },
  ];

  if (!record?.data) return <></>;
  const groupedData = Object.values(
    record?.data?.promiser.reduce((acc: any, obj: IPayment) => {
      const key = Number(obj.vamaLender.offer.offerLender.uuid);
      if (!acc[key]) {
        acc[key] = {
          uuid: key,
          key: key,
          summ: 0,

          orgName: obj.vamaLender.offer.offerLender.orgName,
          data: [],
        };
      }
      (acc[key].summ +=
        +obj.vamaLender.offer.priceFirm * +obj.vamaLender.cursLender),
        acc[key].data.push(obj);
      return acc;
    }, {}),
  );
  return (
    <Modal
      title="Агентский список клиентов"
      style={{ minWidth: '1200px' }}
      open={open}
      onCancel={cancel}
      footer={[
        <Button key="back" onClick={cancel}>
          Выход
        </Button>,
      ]}
    >
      <Table
        size="small"
        style={{ position: 'relative' }}
        loading={isLoading}
        columns={columns}
        expandable={{ expandedRowRender }}
        dataSource={groupedData}
        scroll={{ x: 'max-content' }}
      />
    </Modal>
  );
};
