import { useNavigation, useOne } from '@pankod/refine-core';
import {
  Button,
  Card,
  Col,
  Descriptions,
  DescriptionsProps,
  Empty,
  Flex,
  Modal,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IAct, IVama } from 'interfaces';
import moment from 'moment';
import { ClientType } from 'enums/client.type';
import ModalEditAct from './editAct';
import { API_URL } from 'App';
import { ActType } from 'enums/act.type';

export default function ModalInfo({
  open,
  onClose,
  selectedUuid,
  refetch,
}: {
  open: boolean;
  onClose: any;
  selectedUuid: number;
  refetch: any;
}) {
  const { t } = useTranslation();
  const { data, refetch: reload } = useOne<IVama>({
    resource: 'vama',
    id: selectedUuid,
  });
  const [modalEditAct, setModalEditAct] = useState<boolean>(false);

  const { show } = useNavigation();

  if (!data) {
    return <></>;
  }
  const { data: record } = data;

  const itemsLender: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Компания',
      children: record.offer.offerLender.orgName,
    },
    {
      key: '2',
      label: 'Оплата',
      children: `${record.offer.priceFirm} ${record.offer.currencyFirm}`,
    },
    {
      key: '3',
      label: 'Компания RCI',
      children: record.offer.company,
    },
    {
      key: '4',
      label: 'Сотрудник',
      children: `${record.offer.user.lastName} ${record.offer.user.firstName}`,
    },
    {
      key: '5',
      label: 'Курс при растаможке',
      children: !!Number(record.cursLender) ? record.cursLender : '-',
    },
    {
      key: '6',
      label: 'Сумма(лей)',
      children: !!Number(record.cursLender)
        ? Number(+record.offer.priceFirm * +record.cursLender).toLocaleString(
            'ru-RU',
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          )
        : '-',
    },
  ];
  const itemsDriver: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Компания',
      children: record.offer.offerDriver.orgName,
    },
    {
      key: '2',
      label: 'Оплата',
      children: `${record.offer.priceDriver} ${record.offer.currencyDriver}`,
    },
    {
      key: '3',
      label: 'Маршрут',
      children: record.offer.way,
    },
    {
      key: '4',
      label: 'Авто',
      children: record.offer.carNumber,
    },
    {
      key: '5',
      label: 'Растаможка',
      children: record.datePayment
        ? moment(record.datePayment).format('DD.MM.YYYY')
        : '-',
    },
    {
      key: '5',
      label: 'Курс при растаможке',
      children: !!Number(record.cursDriver) ? record.cursDriver : '-',
    },
    {
      key: '6',
      label: 'Сумма(лей)',
      children: !!Number(record.cursDriver)
        ? Number(+record.offer.priceDriver * +record.cursDriver).toLocaleString(
            'ru-RU',
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          )
        : '-',
    },
  ];

  return (
    <>
      <ModalEditAct
        refetch={reload}
        uuid={record.offer.uuid}
        onCancel={() => setModalEditAct(false)}
        open={modalEditAct}
        type={ActType.EXPEDITION}
      />
      <Modal
        title={`${t('offer.info.title')} ${record.offer.offerNumber}`}
        open={open}
        onCancel={onClose}
        footer={[]}
        width={'100%'}
        style={{ maxWidth: '1000px' }}
      >
        <Row gutter={[12, 12]}>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Card
              size="small"
              title={t('offer.lender')}
              hoverable
              extra={
                record.commentLender
                  ? [
                      <Tooltip title={record.commentLender} arrow>
                        <div style={{ color: '#2380e7' }}>
                          Есть уведомление <ExclamationCircleOutlined />
                        </div>
                      </Tooltip>,
                    ]
                  : []
              }
            >
              <Descriptions
                column={1}
                contentStyle={{ display: 'flex', justifyContent: 'end' }}
                size={'small'}
                items={itemsLender}
              />
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Card
              size="small"
              title={t('offer.driver')}
              hoverable
              extra={
                record.commentDriver
                  ? [
                      <Tooltip title={record.commentDriver} arrow>
                        <div style={{ color: '#2380e7' }}>
                          Есть уведомление <ExclamationCircleOutlined />
                        </div>
                      </Tooltip>,
                    ]
                  : []
              }
            >
              <Descriptions
                column={1}
                contentStyle={{ display: 'flex', justifyContent: 'end' }}
                size={'small'}
                items={itemsDriver}
              />
            </Card>
          </Col>
          <Col xs={{ span: 24 }}>
            <Card size="small" title={t('offer.total')}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Card size="small" title={t('offer.lender')} hoverable>
                    <Space size={12}>
                      <Button
                        size="small"
                        type="primary"
                        target="_blank"
                        // onClick={() =>
                        //   show(
                        //     'blanks/offers',
                        //     `${record.offer.uuid}&${ClientType.FIRM}`,
                        //     'push',
                        //   )
                        // }
                        href={`/blanks/offers/show/${record.offer.uuid}&${ClientType.FIRM}`}
                      >
                        Заявка
                      </Button>
                      <Button
                        size="small"
                        type="primary"
                        target="_blank"
                        // onClick={() =>
                        //   show(
                        //     'invoice',
                        //     `${record.offer.uuid}&${ClientType.FIRM}`,
                        //   )
                        // }
                        href={`/invoice/show/${record.offer.uuid}&${ClientType.FIRM}`}
                      >
                        Инвойс
                      </Button>
                      <Button
                        size="small"
                        type="primary"
                        target="_blank"
                        // onClick={() =>
                        //   show('act', `${record.offer.uuid}&${ClientType.FIRM}`)
                        // }
                        href={`/act/show/${record.offer.uuid}&${ClientType.FIRM}`}
                        disabled={!record.offer.act}
                      >
                        Акт
                      </Button>
                    </Space>
                  </Card>
                </Col>
                <Col>
                  <Card size="small" title={t('offer.driver')} hoverable>
                    <Space size={12}>
                      <Button
                        size="small"
                        type="primary"
                        target="_blank"
                        // onClick={() =>
                        //   show(
                        //     'blanks/offers',
                        //     `${record.offer.uuid}&${ClientType.TRANSPORT}`,
                        //     'push',
                        //   )
                        // }
                        href={`/blanks/offers/show/${record.offer.uuid}&${ClientType.TRANSPORT}`}
                      >
                        Заявка
                      </Button>
                      <Button
                        size="small"
                        type="primary"
                        target="_blank"
                        // onClick={() => show('ticket', `${record.offer.uuid}`)}
                        href={`/ticket/show/${record.offer.uuid}`}
                      >
                        Квитанция перевозчику
                      </Button>
                      <Button
                        size="small"
                        type="primary"
                        target="_blank"
                        href={`/invoice/show/${record.offer.uuid}&${ClientType.TRANSPORT}`}
                      >
                        Инвойс
                      </Button>
                      <></>
                      <Button
                        size="small"
                        type="primary"
                        target="_blank"
                        onClick={() =>
                          show(
                            'act',
                            `${record.offer.uuid}&${ClientType.TRANSPORT}`,
                          )
                        }
                        href={`/act/show/${record.offer.uuid}&${ClientType.TRANSPORT}`}
                        disabled={!record.offer.act}
                      >
                        Акт
                      </Button>
                    </Space>
                  </Card>
                </Col>
                <Col>
                  <Card size="small" title={t('offer.edit')} hoverable>
                    <Space size={12}>
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => setModalEditAct(true)}
                      >
                        Акт
                      </Button>
                    </Space>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24}>
            <Card size="small" title="Документы растаможки">
              {record.pictures?.length === 0 ? (
                <Empty />
              ) : (
                <Flex justify="center" gap={16} vertical>
                  {record.pictures?.map((doc) => {
                    return (
                      <Flex>
                        <Button
                          onClick={() =>
                            window.open(API_URL + doc.path, '_blank')
                          }
                          size="small"
                          type="primary"
                        >
                          {doc.origName}
                        </Button>
                      </Flex>
                    );
                  })}
                </Flex>
              )}
            </Card>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
