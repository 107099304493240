import {
  CloudDownloadOutlined,
  DeleteOutlined,
  InboxOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Button,
  Flex,
  Grid,
  List,
  Popconfirm,
  Popover,
  Spin,
  theme,
  Tooltip,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import React, { ReactElement } from 'react';
import { DocumentType } from '../../enums/document.type';
import {
  IAirplane,
  IOffer,
  IPicture,
  IShip,
  IUser,
  IVama,
} from '../../interfaces';
import './dragAndDrop.css';
import { downloadFile } from '../../utility/downloadFile';
import { UserStatus } from '../../enums/user.status';
import { API_URL, axiosInstance } from 'App';
import { useFileUploadState } from '@pankod/refine-antd';
import { useApiUrl, useNotification } from '@pankod/refine-core';
import { uuidV4 } from 'utility/uuidv4';
import { Role } from 'enums/role';

const { Dragger } = Upload;
const { useToken } = theme;

function filterArrayByType(
  array: IPicture[] | undefined,
  type: DocumentType,
): UploadFile<any>[] {
  if (!array) return [];
  return array
    .filter((item) => item.type === type) // Фильтруем по типу
    .map((item) => ({
      name: item.origName ? item.origName : type,
      path: item.path,
      uid: item.id,
    })); // Форматируем выходные данные
}

const { useBreakpoint } = Grid;

const ImageDragAndDrop = ({
  type,
  defaultList,
  user,
  offer,
  form,
  vama,
  setUploadedFiles,
  employee,
  refetch,
  ship,
  airplane,
}: {
  type: DocumentType;
  defaultList: IPicture[] | undefined;
  user?: IUser | undefined;
  vama?: IVama | undefined;
  ship?: number | undefined;
  offer?: IOffer | undefined;
  airplane?: number | undefined;
  form?: any;
  setUploadedFiles?: any;
  employee?: boolean;
  refetch?: any;
}) => {
  const screens = useBreakpoint();

  const files: string[] = [];
  const { onChange } = useFileUploadState();
  const { open: notify } = useNotification();
  const apiUrl = useApiUrl();
  const { token } = useToken();

  const hide = async (file: any) => {
    if (notify) {
      try {
        await axiosInstance.post(apiUrl + '/media/remove', { file });
        notify({
          description: 'Документ удален',
          message: 'Данный документ был удален из RCI systems',
          type: 'success',
          key: '2',
          undoableTimeout: 20000,
        });
        await refetch();
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: 'Не удалось удалить файл',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };

  const props: UploadProps = {
    name: 'file',
    listType: 'text',
    multiple: true,

    style: {
      background: 'transparent',
      width: screens.xs ? 'auto' : '320px',
      borderRadius: 16,
    },
    itemRender: (
      originNode: ReactElement,
      file: UploadFile,
      fileList: any[],
      actions: { download: any; preview: any; remove: any },
    ) => {
      return (
        <List.Item
          style={{ padding: '2px 12px' }}
          className="selected_documents"
        >
          <Spin spinning={file.status === 'uploading'}>
            <Flex justify="space-between" align="center">
              {/* <Button type="text"> */}

              <Typography.Text
                ellipsis
                style={{
                  maxWidth: '220px',
                  color: file.status === 'error' ? token.colorError : '#000',
                }}
              >
                {file.status === 'error' && (
                  <WarningOutlined style={{ marginRight: 2 }} />
                )}
                {file.name}
              </Typography.Text>
              {/* </Button>{" "} */}
              {file.status !== 'error' && (
                <Flex gap={'small'}>
                  <Tooltip title="Просмотреть документ">
                    <Button
                      size="small"
                      type="text"
                      onClick={actions.preview}
                      icon={<SearchOutlined />}
                    />
                  </Tooltip>
                  <Tooltip title="Скачать документ">
                    <Button
                      size="small"
                      type="text"
                      onClick={actions.download}
                      icon={<CloudDownloadOutlined />}
                    />
                  </Tooltip>
                  {user?.role &&
                    [Role.ACCOUNTANT, Role.DIRECTOR].includes(user.role) && (
                      <Tooltip title="Удалить документ">
                        <Popover
                          arrow
                          arrowContent
                          trigger={'click'}
                          content={
                            <Flex vertical gap={'middle'}>
                              <Typography.Text type="secondary">
                                Подтвердите намерение удалить документ
                              </Typography.Text>
                              <Flex gap={'small'} align="end" justify="end">
                                <Tooltip
                                  title={
                                    employee
                                      ? 'Скрыть (у пользователей видно что удалится)'
                                      : 'Удалить из системы'
                                  }
                                  showArrow
                                >
                                  <Button
                                    danger
                                    type="primary"
                                    size="small"
                                    onClick={async () => {
                                      await actions.remove();
                                      await hide(file);
                                    }}
                                  >
                                    Удалить
                                  </Button>
                                </Tooltip>
                              </Flex>
                            </Flex>
                          }
                          title="Подтверждение удаления"
                        >
                          <Button
                            size="small"
                            type="text"
                            icon={<DeleteOutlined />}
                          />
                        </Popover>
                      </Tooltip>
                    )}
                </Flex>
              )}
            </Flex>
          </Spin>
        </List.Item>
      );
    },
    defaultFileList: filterArrayByType(defaultList, type),
    maxCount: 5,
    hasControlInside: true,
    action: `${apiUrl}/media/upload`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },

    beforeUpload: (file) => {
      if (!notify) return;
      const isSizeValid = file.size / 1024 / 1024 < 10;
      const isTypeValid =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/gif' ||
        file.type === 'image/jpg' ||
        file.type === 'application/pdf' ||
        file.type === 'application/vnd.ms-excel' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        file.type === 'application/octet-stream';
      file.type === 'application/pdf';

      if (!isSizeValid) {
        notify({
          description: 'Ошибка загрузки',
          message: 'Размер файла слишком большой, загрузка не была произведена',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }

      if (!isTypeValid) {
        notify({
          description: 'Ошибка загрузки',
          message: 'Тип файла не поддерживается, загрузка не была произведена',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
      return isSizeValid && isTypeValid;
    },

    data: (value) => {
      const fileUuid = uuidV4();
      files.push(fileUuid);
      form?.setFieldsValue({
        files,
      });
      if (user) {
        if (vama) {
          return {
            fileUuid,
            userUuid: user?.uuid,
            origName: value.name,
            vama: vama.uuid,
            type: type,
          };
        }
        if (ship) {
          return {
            fileUuid,
            userUuid: user?.uuid,
            origName: value.name,
            shipUuid: ship ? ship : null,
            type: type,
          };
        }
        if (airplane) {
          return {
            fileUuid,
            userUuid: user?.uuid,
            origName: value.name,
            airplaneUuid: airplane ? airplane : null,
            type: type,
          };
        }
        return {
          fileUuid,
          userUuid: user?.uuid,
          origName: value.name,
          type: type,
        };
      }

      return {
        fileUuid,
        origName: value.name,
        offerUuid: offer ? offer.uuid : null,
        shipUuid: ship ? ship : null,
        vama: vama ? vama.uuid : null,
        type: type,
      };
    },
    onPreview: (file: any) => {
      window.open(API_URL + file.path, '_blank');
    },
    onDownload: (file) => {
      downloadFile(file);
    },
    onRemove: () => {
      //  employee ? removeFile : hide,
    },
  };

  return (
    <Flex
      style={{
        width: screens.xs ? '100%' : '320px',
        border: '1px dashed grey',
        minHeight: '240px',
        borderRadius: 16,
        borderColor: token.colorPrimaryActive,
      }}
      vertical={screens.xs ? true : false}
      align={screens.xs ? 'normal' : 'flex-start'}
      justify={screens.xs ? 'normal' : 'center'}
    >
      <Dragger
        {...props}
        onChange={(e) => {
          if (e.file.status === 'done') {
            refetch();
          }
          onChange(e);
        }}
        // disabled={
        //   !defaultList ||
        //   defaultList.filter((item) => item.type === type).length > 5
        // }
      >
        <p className="ant-upload-text">{type}</p>
        <Button
          type="text"
          style={{ color: token.colorPrimaryActive }}
          icon={<InboxOutlined />}
        >
          Загрузить
        </Button>
      </Dragger>
    </Flex>
  );
};

export default ImageDragAndDrop;
