import { useNotification, useUpdate } from '@pankod/refine-core';
import { Button, Flex, Form, Input, Modal, Typography } from 'antd';
import { IUser } from 'interfaces';
import React, { useState } from 'react';

const EmailPasswordModal = ({ me }: { me: IUser }) => {
  const { open: notify } = useNotification();
  const [open, setOpen] = useState<boolean>(me.emailPassword === null);
  const { mutate: update } = useUpdate();
  const [form] = Form.useForm();
  const handleSubmit = async () => {
    const data = form.getFieldsValue();
    try {
      if (notify) {
        await update({
          resource: 'users',
          id: me.uuid,
          values: { emailPassword: data.emailPassword },
        });
        notify({
          type: 'success',
          description: 'Успешно',
          message: 'Пароль отправлен в систему',
        });
        setOpen(false);
      }
    } catch (error) {
      if (notify) {
        notify({
          type: 'error',
          description: 'Ошибка',
          message: 'Возникла непредвиденная ошибка',
        });
      }
    }
  };

  return (
    <>
      {me && (
        <Modal
          open={open}
          closable={false}
          footer={[]}
          width={600}
          title="Уважаемый пользователь"
        >
          <Flex vertical gap={16}>
            <Typography.Text>
              В связи с новыми правилами компании{' '}
              <Typography.Text strong>R.C.I. company</Typography.Text> ,
              компания приняла решение хранить данные почновой информации .
              <br />
              Для этого вам необходимо будет ввести{' '}
              <Typography.Text strong>АКТУАЛЬНЫЙ!</Typography.Text>
              пароль от рабочей ПОЧТЫ!{' '}
              <Typography.Text strong type="danger">
                (не от платформы)
              </Typography.Text>{' '}
              <br />
              Чтобы узнать пароль от вашей Яндекс почты, перейдите в браузере:{' '}
              <Typography.Text strong>
                Три точки {'->'} Пароли и автозаполнение{'->'} Google Менеджер
                паролей
              </Typography.Text>
            </Typography.Text>
            <Typography.Text strong type="danger">
              НЕОБХОДИМО ВВЕСТИ ПРАВИЛЬНЫЙ ПАРОЛЬ, ИНАЧЕ ПРОФИЛЬ БУДЕТ
              ДЕАКТИВИРОВАН
            </Typography.Text>
            <Form layout="vertical" form={form} onFinish={handleSubmit}>
              <Form.Item
                rules={[{ required: true }]}
                name={'emailPassword'}
                label={`Пароль от ${me.email}`}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Flex justify="end">
                  <Button type="primary" htmlType="submit">
                    Отправить пароль
                  </Button>
                </Flex>
              </Form.Item>
            </Form>
          </Flex>
        </Modal>
      )}
    </>
  );
};

export default EmailPasswordModal;
