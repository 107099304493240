import axios from 'axios';

export const downloadFile = (file: any) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}${file.path}`; // замените на фактический URL файла
  axios({
    url,
    method: 'GET',
    responseType: 'blob', // указываем тип ответа как blob
  })
    .then((response) => {
      console.log(file);
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', file.name); // замените на желаемое имя файла с расширением
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => {
      console.error('Ошибка при скачивании файла:', error);
    });
};
