import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useCan,
  useDelete,
  useGetIdentity,
  useNavigation,
  useUpdate,
} from '@pankod/refine-core';
import {
  Avatar,
  Button,
  CreateButton,
  DateField,
  DatePicker,
  DeleteButton,
  Dropdown,
  EditButton,
  EmailField,
  FilterDropdown,
  FilterDropdownProps,
  getDefaultSortOrder,
  Input,
  List,
  MenuItemProps,
  MenuProps,
  message,
  NumberField,
  Select,
  ShowButton,
  Space,
  Spin,
  Table,
  Tag,
  TagField,
  TextField,
  Tooltip,
  useTable,
} from '@pankod/refine-antd';

import {
  SolutionOutlined,
  FileAddOutlined,
  UserOutlined,
  DownOutlined,
  EllipsisOutlined,
  CalendarOutlined,
  MailOutlined,
  SignatureOutlined,
  WarningOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  IssuesCloseOutlined,
  ClockCircleOutlined,
  StarFilled,
  StarOutlined,
  PhoneFilled,
} from '@ant-design/icons';
import { ICalendar, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, { CSSProperties, useContext, useState } from 'react';
import { UserContext } from 'UserProvider';
import ModalAddLender from 'components/modals/addCompanies';
import { ClientType } from 'enums/client.type';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ModalAddCalendar from 'components/calendar/addCalendar';
import { CalendarType } from 'enums/calendar.type';
import ModalEditCalendar from 'components/calendar/editCalendar';

export const AssigList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const [showFavorites, setShowFavorites] = useState<boolean>(false);
  const initialFilter: CrudFilters = [
    { field: 'calendars.favorite', operator: 'eq', value: showFavorites },
    { field: 'calendars.type', operator: 'eq', value: CalendarType.ASIG },
    { field: 'user.uuid', operator: 'eq', value: me?.uuid },
  ];

  const { tableProps, sorter, tableQueryResult, setFilters } =
    useTable<ICalendar>({
      resource: 'calendar',
      queryOptions: { retry: 5 },
      initialSorter: [
        {
          field: 'calendars.dateCall',
          order: 'desc',
        },
      ],
      initialFilter,
    });

  const edit: boolean =
    useCan({ resource: 'firms', action: 'edit' }).data?.can || false;
  const changeUser: boolean =
    useCan({ resource: 'firms', action: 'changeUser' }).data?.can || false;

  const { refetch } = tableQueryResult;
  const [openModalAdd, setOpenModal] = useState<boolean>(false);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);

  const { mutate } = useUpdate();
  const { mutate: deleteOne } = useDelete();
  const favoriteChange = (uuid: number, currentStatus: boolean) => {
    mutate({
      resource: 'calendar',
      id: uuid,
      values: { favorite: !currentStatus },
      successNotification: {
        description: t('calendar.update'),
        message: t('calendar.deleteText'),
        type: 'success',
      },
      errorNotification: {
        description: t('error.createText'),
        message: 'Возникла ошибка',
        type: 'error',
      },
    });
  };

  const items = (value: any): MenuProps['items'] => {
    return [
      {
        label: value.favorite
          ? 'Убрать из избранного '
          : 'Добавитиь в избранное',
        key: '1',
        disabled: !edit,
        onClick: () => {
          favoriteChange(value.uuid, value.favorite);
        },
        icon: <SignatureOutlined />,
      },
      {
        key: '2',
        type: 'divider',
      },
      {
        label: 'Редактировать',
        key: '3',
        onClick: () => {
          setSelectedUuid(value.uuid);
          setOpenModalEdit(true);
        },
        icon: <SignatureOutlined />,
      },

      {
        label: t('delete.label'),
        key: '4',
        icon: <DeleteOutlined />,
        danger: true,
        onClick: () => {
          deleteOne({
            resource: 'calendar',
            id: value.uuid,
          });
        },
      },
    ];
  };

  const menuProps = (value: any) => {
    const itms = items(value);

    return { items: itms };
  };

  const changeFavorite = () => {
    setFilters([
      { field: 'calendars.favorite', operator: 'eq', value: !showFavorites },
    ]);
    setShowFavorites(!showFavorites);
  };

  return !tableProps.loading ? (
    <>
      {openModalAdd && (
        <ModalAddCalendar
          open={openModalAdd}
          onCancel={() => setOpenModal(false)}
          refetch={refetch}
          type={CalendarType.ASIG}
          uuid={Number(selectedUuid)}
        />
      )}
      {openModalEdit && (
        <ModalEditCalendar
          open={openModalEdit}
          onCancel={() => setOpenModalEdit(false)}
          refetch={refetch}
          type={CalendarType.ASIG}
          uuid={Number(selectedUuid)}
        />
      )}
      <List
        headerProps={{
          extra: (
            <Button
              onClick={() => setOpenModal(true)}
              type={'primary'}
              children={t('calendar.add')}
            />
          ),
        }}
      >
        <Button
          onClick={changeFavorite}
          type={!showFavorites ? 'primary' : 'dashed'}
          icon={!showFavorites ? <StarFilled /> : <StarOutlined />}
          children={!showFavorites ? 'Показать избранное' : 'Скрыть избранное'}
          style={{ marginBottom: '12px' }}
        />
        <Table
          {...tableProps}
          rowKey="uuid"
          pagination={{
            ...tableProps.pagination,
            position: ['bottomRight'],
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '200'],
            size: 'small',
          }}
          scroll={{ x: 'max-content' }}
          size="small"
        >
          <Table.Column
            dataIndex="companies"
            key="companies"
            title={t('calendar.companies')}
            render={(companies, value: any) => (
              <Tag
                style={{ cursor: 'pointer' }}
                color={value.color ? value.color : '#0092fd'}
              >
                {companies.orgName}
              </Tag>
            )}
          />
          <Table.Column
            dataIndex="companies"
            key="companies"
            title={t('calendar.contact')}
            render={(value) => (
              <Space size={2} direction="vertical">
                {value.contact ? (
                  JSON.parse(value.contact).map(
                    (contact: { name: string; phone: string }) => (
                      <Button
                        href={`viber://chat?number=%2B${contact.phone.replace(
                          '+',
                          '',
                        )}`}
                        icon={<PhoneFilled />}
                        size="small"
                        type="link"
                      >
                        {contact.name} {contact.phone}
                      </Button>
                    ),
                  )
                ) : (
                  <></>
                )}
              </Space>
            )}
          />
          <Table.Column
            dataIndex="dateCall"
            key="dateCall"
            width={150}
            title={t('calendar.dateRecall')}
            render={(value) => (
              <TextField value={moment(value).format('DD.MM.YYYY')} />
            )}
            defaultSortOrder={getDefaultSortOrder('orgName', sorter)}
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <DatePicker disabled />
              </FilterDropdown>
            )}
          />
          <Table.Column
            dataIndex="text"
            key="text"
            title={t('calendar.text')}
            width={500}
            render={(value) => <TextField value={value} />}
          />
          {!showFavorites && (
            <Table.Column
              dataIndex="status"
              key="status"
              align="right"
              title={t('calendar.status')}
              render={(_, value: any) => {
                const daysBetween = moment(
                  moment(value.dateCall).format('YYYY-MM-DD'),
                ).diff(moment().format('YYYY-MM-DD'), 'days');
                return (
                  <Tag
                    color={
                      daysBetween < 0
                        ? '#fd8600'
                        : daysBetween >= 0 && daysBetween < 1
                        ? '#e50000'
                        : '#0065ff'
                    }
                  >
                    {daysBetween < 0 ? (
                      <>
                        <ExclamationCircleOutlined />
                        <TextField
                          value={'Звонок пропущен'}
                          style={{ color: '#fff' }}
                        />
                      </>
                    ) : daysBetween >= 0 && daysBetween < 1 ? (
                      <>
                        <IssuesCloseOutlined />
                        <TextField
                          value={'Звонок сегодня'}
                          style={{ color: '#fff' }}
                        />
                      </>
                    ) : (
                      <>
                        <ClockCircleOutlined />
                        <TextField
                          value={`Звонок через ${daysBetween} (дней)`}
                          style={{ color: '#fff' }}
                        />
                      </>
                    )}
                  </Tag>
                );
              }}
            />
          )}
          <Table.Column
            key="action"
            title=""
            width={20}
            render={(_, value: any) => (
              <>
                <Dropdown
                  menu={menuProps(value)}
                  arrow
                  trigger={['click']}
                  placement="bottomLeft"
                  destroyPopupOnHide
                >
                  <Button
                    icon={<EllipsisOutlined />}
                    shape="circle"
                    type="dashed"
                  />
                </Dropdown>
              </>
            )}
          />
        </Table>
      </List>
    </>
  ) : (
    <Spin>Загрузка</Spin>
  );
};
