export enum Role {
  SYSTEM = 'Программист',
  ADMIN = 'Администратор',
  DIRECTOR = 'Директор',
  SECRETARY = 'Секретарь',
  CONTROL = 'Управляющий',
  ACCOUNTANT = 'Бухгалтер',
  MULTIMODAL = 'Мультимодальные перевозки',
  MANAGER_BIG = 'Старший менеджер',
  MANAGER_BIG_HALF = 'Старший менеджер(начинающий)',
  MANAGER = 'Менеджер',
  MANAGER_TRANSPORT = 'Менеджер по транспорту',
  MANAGER_ASIG = 'Менеджер по страховкам',
  LAWYER = 'Юрист',
}
