export enum DocumentType {
  AVATAR = 'Аватар',
  WORKS = 'Работы',
  REVIEW = 'Отзывы',
  ARTICLES = 'Пункты',
  NEWS = 'Новости',
  DEVAMA = 'Документы растаможки',
  OFFER = 'Документы заявки',
  CMR = 'CMR',
}
