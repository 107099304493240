import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useGetIdentity,
  useNavigation,
  useNotification,
  useShow,
  useUpdate,
} from '@pankod/refine-core';
import { EditButton, Show, Spin, useTable } from '@pankod/refine-antd';

import { IUser } from 'interfaces';
import React, { useState } from 'react';

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const showManual = process.env.REACT_APP_PROD === '0';
  type CategoryMutationResult = {
    id: number;
    title: string;
  };
  const { mutate } = useUpdate<CategoryMutationResult>();
  const showModal = () => {
    setIsModalVisible(true);
  };
  const showModalMinus = () => {
    setIsModalMinusVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOkMinus = () => {
    setIsModalMinusVisible(false);
  };
  const handleCancelMinus = () => {
    setIsModalMinusVisible(false);
  };
  const { data: me } = useGetIdentity();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalMinusVisible, setIsModalMinusVisible] = useState(false);
  const [tinkoffDisabled, setTinkoffDisabled] = useState<boolean>(false);
  const [tinkoffButtonText, setTinkoffButtonText] =
    useState<string>('Подключить Tinkoff');
  const { queryResult } = useShow<IUser>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;
  const [tinkoffMonitorDisabled, setTinkoffMonitorDisabled] =
    useState<boolean>(false);
  const userTypeName = 'Физическое лицо';

  let fio;
  if (record && record?.lastName && record?.firstName && record?.middleName) {
    fio = record?.lastName + ' ' + record?.firstName + ' ' + record?.middleName;
  } else {
    fio = 'Новый пользователь';
  }

  const { open: notify } = useNotification();

  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const initialFilter: CrudFilters = [];

  const { tableProps } = useTable<IUser>({
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    initialFilter,
  });
  let avatar;
  if (record) {
    avatar = record.pictures;
  }

  const [modalSendMsg, setModalSendMsg] = useState<boolean>(false);
  const showSendMessage = async () => {
    setModalSendMsg(true);
  };

  return record ? (
    <>
      <Show
        isLoading={isLoading}
        headerProps={{
          extra: (
            <>
              <div className="my-space">
                <CanAccess resource="users" action="edit">
                  <EditButton
                    style={{ marginLeft: 10, marginTop: 5 }}
                    children="Править"
                  />
                </CanAccess>
              </div>
            </>
          ),
        }}
        contentProps={{
          style: {
            background: 'none',
            // padding: '16px',
          },
        }}
      ></Show>
    </>
  ) : (
    <Spin style={{ padding: 20 }} />
  );
};
