import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useCan,
  useGetIdentity,
  useNavigation,
} from '@pankod/refine-core';
import {
  Avatar,
  Badge,
  Button,
  CreateButton,
  DateField,
  DeleteButton,
  Dropdown,
  EditButton,
  EmailField,
  FilterDropdown,
  FilterDropdownProps,
  getDefaultSortOrder,
  Input,
  List,
  MenuItemProps,
  MenuProps,
  message,
  NumberField,
  Select,
  ShowButton,
  Space,
  Spin,
  Table,
  Tag,
  TagField,
  TextField,
  Tooltip,
  useTable,
} from '@pankod/refine-antd';

import {
  SolutionOutlined,
  FileAddOutlined,
  UserOutlined,
  DownOutlined,
  EllipsisOutlined,
  CalendarOutlined,
  MailOutlined,
  SignatureOutlined,
  WarningOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { ICompanies } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, { CSSProperties, useContext, useState } from 'react';
import { UserContext } from 'UserProvider';
import ModalAddLender from 'components/modals/addCompanies';
import { ClientType } from 'enums/client.type';
import ModalAddCalendar from 'components/calendar/addCalendar';
import { CalendarType } from 'enums/calendar.type';
import { useTranslation } from 'react-i18next';
import ModalEditCompany from 'components/modals/editCompanies';

export const SendersList: React.FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation();
  const { show } = useNavigation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const pageSizeOptions = Number(localStorage.getItem('pageSizeOptions')) || 10;

  const initialFilter: CrudFilters = [];

  const { tableProps, sorter, tableQueryResult } = useTable<ICompanies>({
    resource: 'companies/senders',
    queryOptions: { retry: 5 },
    initialPageSize: pageSizeOptions,
    initialFilter,
  });

  const calendar: boolean =
    useCan({ resource: 'firms', action: 'calendar' }).data?.can || false;
  const edit: boolean =
    useCan({ resource: 'firms', action: 'edit' }).data?.can || false;
  const changeUser: boolean =
    useCan({ resource: 'firms', action: 'changeUser' }).data?.can || false;

  const { refetch } = tableQueryResult;
  const [openModalAdd, setOpenModal] = useState<boolean>(false);
  const [openModalEdit, setModalEdit] = useState<boolean>(false);
  const [openModalAddCalendar, setOpenModalCalendar] = useState<boolean>(false);

  const items = (value: any): MenuProps['items'] => {
    return [
      {
        label: t('calendar.title'),
        key: '1',
        icon: <CalendarOutlined />,
        onClick: () => {
          setSelectedUuid(value.uuid);
          setOpenModalCalendar(true);
        },
      },
      {
        label: 'Копировать Email',
        key: '2',
        disabled: true,
        icon: <MailOutlined />,
      },
      {
        key: '3',
        type: 'divider',
      },
      {
        label: 'Редактировать',
        key: '4',
        disabled: !edit,
        icon: <SignatureOutlined />,
        onClick: () => {
          setSelectedUuid(value.uuid);
          setModalEdit(true);
        },
      },
    ];
  };

  const menuProps = (value: any) => {
    const itms = items(value);

    return { items: itms };
  };

  return !tableProps.loading ? (
    <>
      <CanAccess resource="firms" action="create">
        {openModalAdd && (
          <ModalAddLender
            open={openModalAdd}
            onCancel={() => setOpenModal(false)}
            refetch={refetch}
            type={ClientType.SENDER}
          />
        )}
      </CanAccess>
      {openModalAddCalendar && (
        <ModalAddCalendar
          open={openModalAddCalendar}
          onCancel={() => setOpenModalCalendar(false)}
          refetch={refetch}
          type={CalendarType.ASIG}
          uuid={Number(selectedUuid)}
        />
      )}
      <CanAccess resource="firms" action="edit">
        {openModalEdit && (
          <ModalEditCompany
            open={openModalEdit}
            onCancel={() => setModalEdit(false)}
            refetch={refetch}
            uuid={Number(selectedUuid)}
          />
        )}
      </CanAccess>
      <List
        headerProps={{
          extra: (
            <Button
              onClick={() => setOpenModal(true)}
              type={'primary'}
              children="Добавить фирму"
            />
          ),
        }}
      >
        <Table
          {...tableProps}
          rowKey="uuid"
          pagination={{
            ...tableProps.pagination,
            position: ['bottomRight'],
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '200'],
            size: 'small',
          }}
          // onChange={(value) => {
          //   if (value.pageSize) {
          //     localStorage.setItem('pageSizeOptions', String(value.pageSize));
          //     setPageSize(value.pageSize);
          //   }
          //   if (value.current) {
          //     setCurrent(value.current);
          //   }
          // }}
          size="small"
        >
          <Table.Column
            dataIndex="orgName"
            key="orgName"
            title="Название"
            render={(value, record: any) => (
              <>
                <Badge
                  count={record.offerDriver?.length}
                  style={{ background: 'green' }}
                >
                  <Tag
                    bordered
                    style={{
                      width: '100%',
                      display: 'flex',
                      padding: '4px',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                    color={'#154468'}
                    // onClick={() =>
                    // show('offers', `${value.uuid}&${ClientType.FIRM}`)
                    // }
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {value}
                    </div>
                  </Tag>
                </Badge>
              </>
            )}
            defaultSortOrder={getDefaultSortOrder('orgName', sorter)}
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Input />
              </FilterDropdown>
            )}
          />
          <Table.Column
            dataIndex="contact"
            key="contact"
            title="Контакты"
            render={(value) => {
              // return value;
              if (!value) return <></>;
              const array = JSON.parse(value);

              return (
                <Space size={4} direction="vertical">
                  {array.map((contact: any) => {
                    return (
                      <div>
                        <TextField value={contact.name} />{' '}
                        <TextField value={contact.phone} />
                      </div>
                    );
                  })}
                </Space>
              );
            }}
          />
          <Table.Column
            dataIndex="comment"
            key="comment"
            title="Комментарии"
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            key="action"
            title=""
            width={20}
            render={(_, value: any) => (
              <>
                <Dropdown
                  menu={menuProps(value)}
                  arrow
                  trigger={['click']}
                  placement="bottomLeft"
                  destroyPopupOnHide
                >
                  <Button
                    icon={<EllipsisOutlined />}
                    shape="circle"
                    type="dashed"
                  />
                </Dropdown>
              </>
            )}
          />
        </Table>
      </List>
    </>
  ) : (
    <Spin>Загрузка</Spin>
  );
};
