import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  useForm,
  useSelect,
  theme,
} from '@pankod/refine-antd';
import { CanAccess, useOne, useUpdate } from '@pankod/refine-core';
import { ClientType } from 'enums/client.type';
import { Role } from 'enums/role';
import { tags } from 'enums/tags';
import { ICompanies, IUser } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import type { ColorPickerProps } from 'antd';
import { AutoComplete, ColorPicker } from 'antd';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { cyan, generate, green, presetPalettes, red } from '@ant-design/colors';
import { CalendarType } from 'enums/calendar.type';
import moment from 'moment';

const { TextArea } = Input;

export default function ModalEditCalendar({
  open,
  onCancel,
  refetch,
  type,
  uuid,
}: {
  open: boolean;
  refetch: any;
  onCancel: any;
  type: CalendarType;
  uuid: number;
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { mutate } = useUpdate<ICompanies>();

  const { data } = useOne({ resource: 'calendar', id: uuid });

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  type Presets = Required<ColorPickerProps>['presets'][number];

  const genPresets = (presets = presetPalettes) =>
    Object.entries(presets).map<Presets>(([label, colors]) => ({
      label,
      colors,
    }));
  const { token } = theme.useToken();

  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
    cyan,
  });

  const customPanelRender: ColorPickerProps['panelRender'] = (
    _,
    { components: { Picker, Presets } },
  ) => (
    <Row justify="space-between" wrap={false}>
      <Col span={12}>
        <Presets />
      </Col>
      <Divider type="vertical" style={{ height: 'auto' }} />
      <Col flex="auto">
        <Picker />
      </Col>
    </Row>
  );

  useEffect(() => {
    if (data?.data) {
      form.setFieldsValue({
        uuid: data?.data.uuid,
        orgName: data?.data.companies.orgName,
        dateCall: dayjs(data?.data.dateCall),
        text: data?.data.text,
        type: data?.data.type,
        color: data?.data.color ? data?.data.color : '#0092fd',
      });
    }
  }, [data?.data]);

  const saveUpdate = () => {
    const dataSend = {
      ...form.getFieldsValue(),
    };

    function rgbaToHex(r: number, g: number, b: number, a: number) {
      r = Math.round(r);
      g = Math.round(g);
      b = Math.round(b);
      a = Math.round(a * 255);
      return (
        '#' +
        ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1) +
        (a.toString(16).length < 2 ? '0' : '') +
        a.toString(16)
      );
    }

    dataSend.dateCall = dayjs(dataSend.dateCall).format('YYYY-MM-DD');
    dataSend.color = dataSend.color.metaColor
      ? rgbaToHex(
          dataSend.color.metaColor.r,
          dataSend.color.metaColor.g,
          dataSend.color.metaColor.b,
          dataSend.color.metaColor.a,
        )
      : dataSend.color;
    delete dataSend.orgName;

    mutate({
      resource: 'calendar',
      id: uuid,
      values: dataSend,
      successNotification: {
        description: t('calendar.update'),
        message: t('calendar.createText'),
        type: 'success',
      },
      errorNotification: {
        description: t('error.createText'),
        message: 'Возникла ошибка',
        type: 'error',
      },
    });
    onCancel();
  };

  return (
    <Modal
      onOk={saveUpdate}
      open={open}
      width={580}
      onCancel={onCancel}
      title={`${t('calendar.add')}`}
    >
      <Form
        form={form}
        requiredMark
        layout="horizontal"
        initialValues={{
          dateCall: null,
          color: '#0092fd',
          type: type,
        }}
        size="small"
        scrollToFirstError
        labelCol={{ xs: 8 }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item
            label="Название компании"
            name="orgName"
            labelAlign="left"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label={t('calendar.dateCall')}
            name="dateCall"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              picker="date"
              size="small"
              format={'DD.MM.YYYY'}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            label={t('calendar.text')}
            name="text"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <TextArea rows={3} />
          </Form.Item>
          <Form.Item
            label={t('calendar.type')}
            name="type"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select disabled size="small">
              <Select.Option value={CalendarType.LOGISTIC}>
                {CalendarType.LOGISTIC}
              </Select.Option>
              <Select.Option value={CalendarType.ASIG}>
                {CalendarType.ASIG}
              </Select.Option>
              <Select.Option value={CalendarType.SHIP}>
                {CalendarType.SHIP}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t('calendar.color')}
            name="color"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <ColorPicker
              defaultValue={token.colorPrimary}
              styles={{ popupOverlayInner: { width: 480 } }}
              arrow
              showText
              size="large"
              onChange={(val) => val.toHexString()}
              presets={presets}
              panelRender={customPanelRender}
            />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
}
