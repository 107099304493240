import React, { useContext, useState } from 'react';
import { Role } from '../../enums/role';
import { MessageStatus } from '../../enums/message.status';

import { IMessage, IUser } from '../../interfaces';
import {
  Button,
  Empty,
  Flex,
  Grid,
  Space,
  Spin,
  Table,
  theme,
  Typography,
} from 'antd';
import { WalletOutlined } from '@ant-design/icons';
import { ModalShowMsg } from '../../components/messages/modalShowMsg';
import { axiosInstance } from '../../App';
import {
  CanAccess,
  IResourceComponentsProps,
  useGetIdentity,
} from '@pankod/refine-core';
import CalendarPayments from './calendarPayments';

const { useToken } = theme;

export const CalendarShipList: React.FC<IResourceComponentsProps> = () => {
  const { token } = useToken();
  const screens = Grid.useBreakpoint();

  const { data: me } = useGetIdentity<IUser>();

  return (
    <>
      <CanAccess
        resource="calendar"
        action="ship"
        // onUnauthorized={() => push("/404")}
      >
        <Flex vertical>
          <Flex
            style={{
              borderBottom: '1px solid #e0e0e0',
            }}
            justify="space-between"
            align="center"
          >
            <Flex
              gap={'small'}
              justify="center"
              align="center"
              style={{ paddingLeft: screens.xs ? '40px' : 0 }}
            >
              <Typography.Title level={5}>
                Календарь морских перевозок
              </Typography.Title>
            </Flex>
          </Flex>
          <Flex justify="space-between" align="center">
            {me && <CalendarPayments me={me} />}
          </Flex>
        </Flex>
      </CanAccess>
    </>
  );
};
