import {
  Button,
  EmailField,
  Modal,
  Table,
  TagField,
  TextField,
  Tooltip,
} from '@pankod/refine-antd';
import { UserStatus } from 'enums/user.status';
import { IPayment, IShip, IUser } from 'interfaces';
import { SolutionOutlined } from '@ant-design/icons';

import { CSSProperties, useEffect, useState } from 'react';
import {
  useNavigation,
  useNotification,
  useOne,
  useUpdate,
} from '@pankod/refine-core';
import { axiosInstance } from 'App';
import { Badge, Flex, StatisticProps, TableColumnsType, Tag } from 'antd';
import moment from 'moment';
import CountUp from 'react-countup/build/CountUp';
import { firmColor } from 'utility/getColor';
import { useNavigate } from '@pankod/refine-react-router-v6';
import ImageDragAndDrop from 'components/imageDragAndDrop';
import { DocumentType } from 'enums/document.type';
import { useTranslation } from 'react-i18next';

export const UploadAirDocumentModal = ({
  me,
  open,
  close,
  refetch,
  record,
}: {
  me: IUser;
  record: number;
  open: boolean;
  close: () => void;
  refetch: any;
}) => {
  const { mutate: updateShip } = useUpdate({});
  const { t } = useTranslation();

  const refetchRecord = () => {
    try {
      updateShip(
        {
          resource: 'ships',
          id: record,
          values: { order: true },
          successNotification: {
            description: t('update.titleSuccess'),
            message: t('update.textSuccess'),
            type: 'success',
          },
          errorNotification: {
            description: t('error.createText'),
            message: 'Возникла ошибка',
            type: 'error',
          },
        },
        {
          onSuccess: () => {
            refetch();
          },
        },
      );
    } catch (error) {}
  };

  return (
    <Modal
      title="Загрузить документ морской перевозки"
      style={{ minWidth: '500px' }}
      open={open}
      onCancel={close}
      footer={[]}
    >
      <Flex justify="center">
        <ImageDragAndDrop
          type={DocumentType.OFFER}
          user={me}
          airplane={record}
          defaultList={[]}
          refetch={refetchRecord}
        />
      </Flex>
    </Modal>
  );
};
