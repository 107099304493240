import { useNavigation, useOne } from '@pankod/refine-core';
import {
  Button,
  Card,
  Col,
  Descriptions,
  DescriptionsProps,
  Empty,
  Flex,
  Modal,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IAct, IVama } from 'interfaces';
import moment from 'moment';
import { ClientType } from 'enums/client.type';
import ModalEditAct from './editAct';
import { API_URL } from 'App';
import { CurrencyType } from 'enums/currency.type';
import { TextField } from '@pankod/refine-antd';
import { ActType } from 'enums/act.type';

export default function ModalShipInfo({
  open,
  onClose,
  selectedUuid,
  refetch,
}: {
  open: boolean;
  onClose: any;
  selectedUuid: number;
  refetch: any;
}) {
  const { t } = useTranslation();
  const { data, refetch: reload } = useOne<IVama>({
    resource: 'vama',
    id: selectedUuid,
  });
  const [modalEditAct, setModalEditAct] = useState<boolean>(false);

  const { show } = useNavigation();

  if (!data) {
    return <></>;
  }
  const { data: record } = data;

  const itemsLender: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Компания',
      children: <TextField value={record.ship?.client?.orgName} />,
    },
    {
      key: '2',
      label: 'Оплата',
      children: `${record.ship?.priceSell} ${CurrencyType.USD}`,
    },
    {
      key: '3',
      label: 'Компания RCI',
      children: record.ship?.company,
    },
    {
      key: '4',
      label: 'Сотрудник',
      children: `${record.ship?.manager.lastName} ${record.ship?.manager.firstName}`,
    },
    {
      key: '5',
      label: 'Курс при растаможке',
      children: !!Number(record.cursLender) ? record.cursLender : '-',
    },
    {
      key: '6',
      label: 'Сумма(лей)',
      children:
        !!Number(record.cursLender) && record.ship?.priceSell
          ? Number(+record.ship?.priceSell * +record.cursLender).toLocaleString(
              'ru-RU',
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              },
            )
          : '-',
    },
  ];
  const itemsDriver: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Компания',
      children: <TextField value={record.ship?.ship?.orgName} />,
    },
    {
      key: '3',
      label: 'Маршрут',
      children: record.ship?.way,
    },
    {
      key: '4',
      label: 'Транспорт',
      children: record.ship?.containerNumber,
    },
    {
      key: '5',
      label: 'Растаможка',
      children: record.datePayment
        ? moment(record.datePayment).format('DD.MM.YYYY')
        : '-',
    },
    {
      key: '5',
      label: 'Курс при растаможке',
      children: !!Number(record.cursDriver) ? record.cursDriver : '-',
    },
    {
      key: '6',
      label: 'Сумма(лей)',
      children:
        !!Number(record.cursDriver) && record.ship?.priceBuy
          ? Number(+record.ship?.priceBuy * +record.cursDriver).toLocaleString(
              'ru-RU',
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              },
            )
          : '-',
    },
  ];

  return (
    <>
      {record.ship && (
        <ModalEditAct
          refetch={reload}
          uuid={record.ship.uuid}
          onCancel={() => setModalEditAct(false)}
          open={modalEditAct}
          type={ActType.SHIP}
        />
      )}
      <Modal
        title={`${t('ships.info.title', 'Инфо')} ${record.ship?.uuid}/${
          record.ship?.rs
        }`}
        open={open}
        onCancel={onClose}
        footer={[]}
        width={'100%'}
        style={{ maxWidth: '1000px' }}
      >
        <Row gutter={[12, 12]}>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Card
              size="small"
              title={t('ships.client')}
              hoverable
              extra={
                record.commentLender
                  ? [
                      <Tooltip title={record.commentLender} arrow>
                        <div style={{ color: '#2380e7' }}>
                          Есть уведомление <ExclamationCircleOutlined />
                        </div>
                      </Tooltip>,
                    ]
                  : []
              }
            >
              <Descriptions
                column={1}
                contentStyle={{ display: 'flex', justifyContent: 'end' }}
                size={'small'}
                items={itemsLender}
              />
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Card
              size="small"
              title={t('ships.transport')}
              hoverable
              extra={
                record.commentDriver
                  ? [
                      <Tooltip title={record.commentDriver} arrow>
                        <div style={{ color: '#2380e7' }}>
                          Есть уведомление <ExclamationCircleOutlined />
                        </div>
                      </Tooltip>,
                    ]
                  : []
              }
            >
              <Descriptions
                column={1}
                contentStyle={{ display: 'flex', justifyContent: 'end' }}
                size={'small'}
                items={itemsDriver}
              />
            </Card>
          </Col>
          <Col xs={{ span: 24 }}>
            <Card size="small" title={t('ships.total')}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Card size="small" title={t('ships.client')} hoverable>
                    <Space size={12}>
                      {!!record.ship && (
                        <Button
                          size="small"
                          type="primary"
                          target="_blank"
                          onClick={() =>
                            show('blanks/ships', record.ship.uuid, 'push')
                          }
                        >
                          Заявка
                        </Button>
                      )}
                      <Button
                        size="small"
                        type="primary"
                        target="_blank"
                        disabled={record.ship.pictures.length === 0}
                        // onClick={() =>
                        //   show(
                        //     'invoice',
                        //     `${record.ship.uuid}&${ClientType.FIRM}`,
                        //   )
                        // }
                        href={`/shipInvoice/show/${record.ship?.uuid}`}
                      >
                        Инвойс
                      </Button>
                      <Button
                        size="small"
                        type="primary"
                        target="_blank"
                        // onClick={() =>
                        //   show('act', `${record.ship.uuid}&${ClientType.FIRM}`)
                        // }
                        href={`/actship/show/${record.ship.uuid}&${ClientType.FIRM}`}
                        disabled={!record.ship.act}
                      >
                        Акт
                      </Button>
                    </Space>
                  </Card>
                </Col>

                <Col>
                  <Card size="small" title={t('ships.edit')} hoverable>
                    <Space size={12}>
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => setModalEditAct(true)}
                      >
                        Акт
                      </Button>
                    </Space>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24}>
            <Card size="small" title="Документы растаможки">
              {record.ship.pictures?.length === 0 ? (
                <Empty />
              ) : (
                <Flex justify="center" gap={16} vertical>
                  {record.ship.pictures?.map((doc) => {
                    return (
                      <Flex>
                        <Button
                          onClick={() =>
                            window.open(API_URL + doc.path, '_blank')
                          }
                          size="small"
                          type="primary"
                        >
                          {doc.origName}
                        </Button>
                      </Flex>
                    );
                  })}
                </Flex>
              )}
            </Card>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
