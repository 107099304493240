import {
  AutoComplete,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  SelectProps,
  Space,
  useForm,
  useSelect,
} from '@pankod/refine-antd';
import { CanAccess, useLogout } from '@pankod/refine-core';
import { ClientType } from 'enums/client.type';
import { Role } from 'enums/role';
import { tags } from 'enums/tags';
import { ICompanies, IUser } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { ContactType } from 'enums/contact.type';
import { convertEnumToOptions } from 'utility/enumToArray';

const { TextArea } = Input;

export default function ModalAddLender({
  open,
  onCancel,
  refetch,
  type,
}: {
  open: boolean;
  refetch: any;
  onCancel: any;
  type: ClientType;
}) {
  const { formProps, saveButtonProps, queryResult, form, formLoading } =
    useForm<ICompanies>({
      resource: 'companies',
      action: 'create',
      redirect: false,
      onMutationSuccess: () => {
        onCancel();
        refetch();
      },
      successNotification: {
        description: `${type} создан`,
        message: `Просматривайте в разделе ${type}`,
        type: 'success',
      },
      errorNotification: {
        description: `${type} создан`,
        message: 'Возникла ошибка',
        type: 'error',
      },
    });

  const { queryResult: selectResult } = useSelect({
    resource: 'companies/createFirms',
  });
  const { data } = selectResult;

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  const [firmIsInValid, setIsInValid] = useState<boolean>(false);

  const checkFirm = (event: any) => {
    const inputFirm = form.getFieldValue('orgName');
    const isFind = data?.data.find(
      (firm) => firm.orgName?.toUpperCase() === inputFirm?.toUpperCase(),
    );
    if (isFind) {
      setIsInValid(true);
    } else {
      setIsInValid(false);
    }
  };

  saveButtonProps.disabled = firmIsInValid;

  return (
    <Modal
      okButtonProps={saveButtonProps}
      confirmLoading={formLoading}
      open={open}
      width={'100%'}
      style={{ maxWidth: '1000px' }}
      onCancel={onCancel}
      title={`Добавить ${type}а`}
    >
      <Form
        {...formProps}
        form={form}
        requiredMark
        layout="horizontal"
        initialValues={{
          contact: [{ name: '', phone: '' }],
          tags: ['MD'],
          type: type,
        }}
        size="small"
        scrollToFirstError
        labelCol={{ xs: 8 }}
      >
        <Form.Item
          label="Название компании"
          name="orgName"
          labelAlign="left"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <div
            onContextMenu={(event) => {
              event.preventDefault(); // Запрещаем контекстное меню (вставка)
            }}
          >
            {type === ClientType.FIRM ? (
              <AutoComplete
                options={data?.data.map((option) => {
                  return { value: option.orgName, label: option.orgName };
                })}
                allowClear
                virtual
                style={{ textTransform: 'uppercase' }}
                status={firmIsInValid ? 'error' : ''}
                onChange={checkFirm}
                onKeyDown={(event) => {
                  // Проверяем, была ли нажата клавиша "V" вместе с "Ctrl" или "Cmd"
                  if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
                    console.log('test');
                    event.preventDefault(); // Запрещаем вставку
                  }
                }}
                placeholder="Запрещено добавлять дубликаты"
                filterOption={(inputValue, option) => {
                  return (
                    option?.value
                      .toUpperCase()
                      .indexOf(inputValue && inputValue.toUpperCase()) !== -1
                  );
                }}
              />
            ) : (
              <Input
                size="small"
                style={{ textTransform: 'uppercase' }}
                placeholder="Название компании"
              />
            )}
          </div>
        </Form.Item>
        {[ClientType.FIRM, ClientType.TRANSPORT].includes(type) && (
          <>
            <Form.Item
              label="Фискальный код"
              name="codFiscal"
              hasFeedback
              labelAlign="left"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber
                placeholder="Фискальный код"
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              label="TVA"
              name="tva"
              hasFeedback
              labelAlign="left"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber placeholder="TVA" style={{ width: '100%' }} />
            </Form.Item>
          </>
        )}
        <Form.Item label="Контакты" required labelAlign="left">
          <Form.List name="contact">
            {(fields, { add, remove }, item) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    rowGap: 6,
                    flexDirection: 'column',
                  }}
                >
                  {fields.map((field) => (
                    <Card size="small" style={{ paddingTop: '12px' }} hoverable>
                      <Row gutter={8}>
                        <Col span={12}>
                          <Form.Item required name={[field.name, 'name']}>
                            <Input placeholder="ФИО" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            required
                            rules={[
                              {
                                required: true,
                                message: 'Заполните телефон',
                              },
                            ]}
                            name={[field.name, 'phone']}
                          >
                            <Input required placeholder="Телефон" />
                          </Form.Item>
                        </Col>
                        {[ClientType.FIRM, ClientType.TRANSPORT].includes(
                          type,
                        ) && (
                          <>
                            <Col span={12}>
                              <Form.Item
                                required
                                name={[field.name, 'position']}
                              >
                                <AutoComplete
                                  options={convertEnumToOptions(ContactType)}
                                  placeholder="Должность"
                                  allowClear
                                  filterOption={(inputValue, option: any) =>
                                    option.value
                                      .toUpperCase()
                                      .indexOf(inputValue.toUpperCase()) !== -1
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col span={10}>
                              <Form.Item required name={[field.name, 'email']}>
                                <Input placeholder="Почтовый адрес" />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                        {fields.length > 1 && (
                          <Col
                            span={2}
                            style={{
                              display: 'flex',
                              alignItems: 'baseline',
                            }}
                          >
                            <Button
                              size="small"
                              danger
                              type="primary"
                              icon={<CloseOutlined />}
                              onClick={() => remove(field.name)}
                            />
                          </Col>
                        )}
                      </Row>
                    </Card>
                  ))}
                  <Button
                    type="primary"
                    style={{ marginBottom: 3 }}
                    onClick={() => add()}
                    block
                  >
                    + Добавить контакт
                  </Button>
                </div>
              );
            }}
          </Form.List>
        </Form.Item>
        <Form.Item
          label="Реквизиты"
          name="rekvizits"
          hasFeedback
          labelAlign="left"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea size="small" rows={5} placeholder="Заполните реквизиты" />
        </Form.Item>
        {[ClientType.FIRM, ClientType.TRANSPORT].includes(type) && (
          <Form.Item
            label="Теги"
            name="tags"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              mode="multiple"
              size="small"
              placeholder="Выберите теги"
              style={{ width: '100%' }}
              options={tags}
            />
          </Form.Item>
        )}
        <Form.Item
          label="Email"
          name="email"
          hasFeedback
          labelAlign="left"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input size="small" placeholder="Электронный адресс" />
        </Form.Item>
        <Form.Item
          label="Комментарий"
          name="comment"
          hasFeedback
          labelAlign="left"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <TextArea size="small" rows={3} placeholder="Опишите компанию" />
        </Form.Item>
        {[ClientType.FIRM, ClientType.TRANSPORT].includes(type) && (
          <Form.Item
            label="Web сайт"
            name="web"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input size="small" placeholder="Веб-сайт компании" />
          </Form.Item>
        )}
        {[ClientType.SENDER].includes(type) && (
          <Form.Item
            label="Адрес"
            name="adresse"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <TextArea rows={3} size="small" placeholder="Введите адрес" />
          </Form.Item>
        )}
        <Form.Item
          label="Регион"
          name="region"
          hasFeedback
          labelAlign="left"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input size="small" placeholder="Введите регион" />
        </Form.Item>
        {type === ClientType.FIRM && (
          <Form.Item
            label="Страна экспорта"
            name="exportCountry"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input size="small" placeholder="Заполните страну экспорта" />
          </Form.Item>
        )}
        <Form.Item
          name="type"
          hasFeedback
          hidden
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
