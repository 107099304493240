import React, { useState, useContext, useEffect } from 'react';
import {
  CanAccess,
  IResourceComponentsProps,
  useApiUrl,
  useCan,
  useGetIdentity,
  useNotification,
  useSelect,
} from '@pankod/refine-core';
import {
  AutoComplete,
  Button,
  Col,
  Create,
  DatePicker,
  Divider,
  Edit,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
  useForm,
  InputNumber,
} from '@pankod/refine-antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

import 'react-mde/lib/styles/css/react-mde-all.css';
import { ICompanies, IPicture, IUser } from 'interfaces';
import { Role } from '../../enums/role';
import { UserContext } from 'UserProvider';
import { Languages } from 'enums/languages.type';
import cargoList from 'constants/cargo';
import conditions from 'constants/condition';
import docs from 'constants/docs';
import { Firms } from 'enums/firms.type';
import transportTypes from 'constants/transport';
import { CurrencyType } from 'enums/currency.type';
import standartVariant from 'constants/standartVariant';
import vamas from 'constants/vama';
import ModalAddLender from 'components/modals/addCompanies';
import { ClientType } from 'enums/client.type';
import { useTranslation } from 'react-i18next';
import { convertEnumToOptions } from 'utility/enumToArray';
import { ShippingType } from 'enums/shipping.type';
import { ShipStatus } from 'enums/ship.status';
import { Condition } from 'enums/shipping.conditions';
import dayjs from 'dayjs';
import moment from 'moment';

export const ShipsEdit: React.FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation();
  const { formProps, saveButtonProps, queryResult, form } = useForm<IUser>({
    successNotification: (data: any, value, test) => {
      return {
        description: t('successfull'),
        message: t('offers.saved'),
        type: 'success',
      };
    },
    errorNotification: {
      description: 'Заявка не обновлена',
      message: 'Возникли проблемы с сохранением в БД',
      type: 'error',
    },
  });
  const [language, setLanguage] = useState<Languages>(Languages.RU);
  const { options: firms, queryResult: firmsQuerryResult } = useSelect<
    ICompanies[]
  >({
    resource: 'companies/offerFirms',
    optionValue: 'uuid',
    optionLabel: 'orgName',
  });
  const { options: drivers, queryResult: driverQuerryResult } = useSelect<
    ICompanies[]
  >({
    resource: 'companies/offerDrivers',
    optionValue: 'uuid',
    optionLabel: 'orgName',
  });

  const {
    options: senders,
    queryResult: { refetch: senderRefetch },
  } = useSelect<ICompanies[]>({
    resource: 'companies/senderFirms',
    optionValue: 'uuid',
    optionLabel: 'orgName',
  });

  const { refetch: firmsRefetch, isLoading: loadingLender } = firmsQuerryResult;
  const { refetch: driversRefetch, isLoading: loadingDriver } =
    driverQuerryResult;

  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;

  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (formProps.initialValues) {
      form.setFieldsValue({
        typeShipping: formProps.initialValues.typeShipping
          ? formProps.initialValues.typeShipping
          : null,
        client: formProps.initialValues.client
          ? formProps.initialValues.client.uuid
          : null,
        cargoSender: formProps.initialValues.cargoSender
          ? formProps.initialValues.cargoSender.uuid
          : null,
        ship: formProps.initialValues.ship
          ? formProps.initialValues.ship.uuid
          : null,
        contactLoad: JSON.parse(formProps.initialValues.contactLoad),
        contactUnLoad: JSON.parse(formProps.initialValues.contactUnLoad),
      });
      form.setFieldsValue({
        eta: formProps.initialValues.eta
          ? dayjs(formProps.initialValues.eta)
          : null,
      });
      form.setFieldsValue({
        etd: formProps.initialValues.etd
          ? dayjs(formProps.initialValues.etd)
          : null,
      });
      form.setFieldsValue({
        cargoReady: dayjs(formProps.initialValues.cargoReady),
      });
      if (formProps.initialValues.typeShipping === ShippingType.FCL) {
        setFcl(true);
      } else {
        setFcl(false);
      }
      setLoading(false);
    }
  }, [formProps.initialValues]);

  const optionsDays: number[] = [];
  for (let i = 1; i <= 31; i++) {
    optionsDays.push(i);
  }

  const optionsCondition = conditions[language].map((condition) => {
    return { label: condition, value: condition };
  });

  const optionsDocs = docs[language].map((doc) => {
    return { label: doc, value: doc };
  });
  const vamaImport = standartVariant[language].map((vamaImp) => {
    return { label: vamaImp, value: vamaImp };
  });
  const vama = vamas[language].map((vamaImp) => {
    return { label: vamaImp, value: vamaImp };
  });

  const [openModalAdd, setOpenModal] = useState<boolean>(false);
  const [type, setType] = useState(ClientType.FIRM);

  const shipStatusOptions: { value: string; label: string }[] = Object.keys(
    ShipStatus,
  ).map((key) => ({
    value: ShipStatus[key as keyof typeof ShipStatus],
    label: ShipStatus[key as keyof typeof ShipStatus],
  }));

  const canAll = useCan({ resource: 'ships', action: 'canAll' }).data?.can;
  const [ifFcl, setFcl] = useState<boolean>(false);

  return queryResult?.data?.data && !loading ? (
    <CanAccess resource="ships" action="edit">
      <Edit
        headerProps={{
          subTitle: '',
        }}
        headerButtons={[
          <Button
            children="Сохранить на устройстве"
            type="primary"
            size="small"
            disabled
          />,
          <Select
            onChange={(value) => {
              setLanguage(value);
              form.setFieldValue('language', value);
            }}
            defaultValue={language}
            variant="outlined"
            size="small"
          >
            <Select.Option value={Languages.RU} children={Languages.RU} />
            <Select.Option value={Languages.RO} children={Languages.RO} />
          </Select>,
        ]}
        title={t('offers.edit.label')}
        saveButtonProps={{ ...saveButtonProps, children: 'Сохранить заявку' }}
      >
        <ModalAddLender
          open={openModalAdd}
          onCancel={() => setOpenModal(false)}
          refetch={type === ClientType.FIRM ? firmsRefetch : driversRefetch}
          type={type}
        />
        <Form {...formProps} layout="vertical">
          <Row gutter={[24, 12]}>
            <Col md={12}>
              <Form.Item
                name="typeShipping"
                label="Загрузка"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={(value) => {
                    if (value === ShippingType.FCL) {
                      setFcl(true);
                    } else {
                      setFcl(false);
                    }
                  }}
                >
                  <Select.Option value={ShippingType.FCL}>
                    {ShippingType.FCL} (FCL)
                  </Select.Option>
                  <Select.Option value={ShippingType.LCL}>
                    {ShippingType.LCL} (LCL)
                  </Select.Option>
                </Select>
              </Form.Item>

              <Row gutter={12}>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item name="oferta" label="Номер договора">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    label="RS"
                    name="rs"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    label="Статус"
                    name="status"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select options={shipStatusOptions} allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={4}>
                <Col flex="auto">
                  <Form.Item
                    label="Заказчик"
                    name="client"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Начните заполнять"
                      optionFilterProp="children"
                      virtual={true}
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toUpperCase()
                          .includes(input.toUpperCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toUpperCase()
                          .localeCompare((optionB?.label ?? '').toUpperCase())
                      }
                      options={firms}
                    />
                  </Form.Item>
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '6px',
                  }}
                >
                  <Tooltip title="Добавить заказчика">
                    <Button
                      icon={<PlusOutlined />}
                      type="text"
                      onClick={() => {
                        setType(ClientType.FIRM);
                        setOpenModal(true);
                      }}
                    />
                  </Tooltip>
                </Col>
              </Row>

              {/* Грузоотправитель */}
              <Row gutter={4}>
                <Col flex="auto">
                  <Form.Item
                    label="Грузоотправитель"
                    name="cargoSender"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Начните заполнять"
                      optionFilterProp="children"
                      virtual={true}
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toUpperCase()
                          .includes(input.toUpperCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toUpperCase()
                          .localeCompare((optionB?.label ?? '').toUpperCase())
                      }
                      options={senders}
                    />
                  </Form.Item>
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '6px',
                  }}
                >
                  <Tooltip title="Добавить грузоотправителя">
                    <Button
                      icon={<PlusOutlined />}
                      type="text"
                      onClick={() => {
                        setType(ClientType.SENDER);
                        setOpenModal(true);
                      }}
                    />
                  </Tooltip>
                </Col>
              </Row>

              {/* <Form.Item name="destination" label="Назначение">
                <Input />
              </Form.Item> */}
              <Row gutter={12}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="POL"
                    name="pol"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="POD"
                    name="pod"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                {ifFcl ? (
                  <Col sm={24}>
                    <Form.Item name="containerType" label="Тип Контейнера">
                      <Input.TextArea autoSize />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col sm={24}>
                    <Form.Item name="containerVolume" label="Объем груза">
                      <Input />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Form.Item name="mass" label="Вес груза">
                <Input />
              </Form.Item>

              <Form.Item name="characterCargo" label="Наименование груза">
                <Input />
              </Form.Item>

              <Form.Item name="cargoReady" label="Дата готовности груза">
                <DatePicker
                  picker="date"
                  style={{ width: '100%' }}
                  format={'DD MMM YYYY'}
                />
              </Form.Item>
              <Form.Item name="adr" label="ADR">
                <Input />
              </Form.Item>

              <Row gutter={4}>
                <Col xs={12}>
                  <Form.Item
                    name="condition"
                    label="Условия"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select options={convertEnumToOptions(Condition)} />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="aditionalPrice"
                    label="Дополнительные расходы"
                    hasFeedback
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <Form.Item
                label="Компания"
                rules={[
                  {
                    required: true,
                    message: 'Пожалйста, выберите компанию',
                  },
                ]}
                name="company"
              >
                <Select
                  options={Object.keys(Firms).map((key) => ({
                    value: Firms[key as keyof typeof Firms],
                    label: Firms[key as keyof typeof Firms],
                  }))}
                />
              </Form.Item>
              <Row gutter={[12, 12]}>
                <Col sm={24} md={12}>
                  <Form.Item
                    label="Ставка покупки"
                    name="priceBuy"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      addonAfter={
                        <Form.Item
                          name="currencyBuy"
                          initialValue={CurrencyType.USD}
                          required
                          noStyle
                        >
                          <Select size="small" style={{ width: 100 }}>
                            <Select.Option value={CurrencyType.EUR}>
                              {CurrencyType.EUR}
                            </Select.Option>
                            <Select.Option value={CurrencyType.USD}>
                              {CurrencyType.USD}
                            </Select.Option>
                            <Select.Option value={CurrencyType.MDL}>
                              {CurrencyType.MDL}
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      }
                    />
                  </Form.Item>
                </Col>

                <Col sm={24} md={12}>
                  <Form.Item
                    label="Ставка продажи"
                    name="priceSell"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      addonAfter={
                        <Form.Item
                          name="currencySell"
                          initialValue={CurrencyType.USD}
                          required
                          noStyle
                        >
                          <Select size="small" style={{ width: 100 }}>
                            <Select.Option value={CurrencyType.EUR}>
                              {CurrencyType.EUR}
                            </Select.Option>
                            <Select.Option value={CurrencyType.USD}>
                              {CurrencyType.USD}
                            </Select.Option>
                            <Select.Option value={CurrencyType.MDL}>
                              {CurrencyType.MDL}
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="way" label="Маршрут следования">
                <Input />
              </Form.Item>
              <Form.Item name="broker" label="Данные брокера">
                <Input.TextArea />
              </Form.Item>
              <Form.Item name="vamaImport" label="Таможня импорта">
                <Input />
              </Form.Item>
              <Form.Item name="vamas" label="Погран переходы">
                <Input />
              </Form.Item>
              <Form.Item name="adressUnloading" label="Адрес разгрузки">
                <Input.TextArea />
              </Form.Item>

              <Form.Item label="Ответсвенный на погрузке">
                <Form.List
                  name="contactLoad"
                  initialValue={[{ fio: '', phone: '' }]}
                >
                  {(fields, { add, remove }, item) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {fields.map((field, idx) => (
                          <Row gutter={8}>
                            <Col flex="auto">
                              <Form.Item hasFeedback name={[field.name, 'fio']}>
                                <Input
                                  placeholder="ФИО"
                                  onPaste={async (e) => {
                                    e.preventDefault();
                                    let number =
                                      await navigator.clipboard.readText();
                                    let text =
                                      await navigator.clipboard.readText();
                                    number = number.replace(/[^+0-9]/g, '');
                                    text = text.replace(/[+0-9]/g, '');

                                    const data =
                                      form.getFieldValue('contactLoad');
                                    data[idx] = {
                                      fio: text.trim(),
                                      phone: number.trim(),
                                    };
                                    console.log(data);

                                    form.setFieldValue('contactLoad', data);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col flex="auto">
                              <Form.Item
                                hasFeedback
                                name={[field.name, 'phone']}
                              >
                                <Input placeholder="Телефон" />
                              </Form.Item>
                            </Col>
                            {fields.length > 1 && (
                              <Col
                                style={{
                                  display: 'flex',
                                  alignItems: 'baseline',
                                }}
                              >
                                <Button
                                  danger
                                  style={{ marginTop: '0px' }}
                                  type="dashed"
                                  icon={<CloseOutlined />}
                                  onClick={() => remove(field.name)}
                                />
                              </Col>
                            )}
                          </Row>
                        ))}
                        <Button
                          type="primary"
                          style={{ marginBottom: 3 }}
                          onClick={() => add()}
                          block
                        >
                          + Добавить контакт
                        </Button>
                      </div>
                    );
                  }}
                </Form.List>
              </Form.Item>

              <Form.Item label="Ответсвенный на разгрузке">
                <Form.List
                  name="contactUnLoad"
                  initialValue={[{ fio: '', phone: '' }]}
                >
                  {(fields, { add, remove }, item) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {fields.map((field, idx) => (
                          <Row gutter={8}>
                            <Col flex="auto">
                              <Form.Item hasFeedback name={[field.name, 'fio']}>
                                <Input
                                  placeholder="ФИО"
                                  onPaste={async (e) => {
                                    e.preventDefault();
                                    let number =
                                      await navigator.clipboard.readText();
                                    let text =
                                      await navigator.clipboard.readText();
                                    number = number.replace(/[^+0-9]/g, '');
                                    text = text.replace(/[+0-9]/g, '');

                                    const data =
                                      form.getFieldValue('contactUnLoad');
                                    data[idx] = {
                                      fio: text.trim(),
                                      phone: number.trim(),
                                    };

                                    form.setFieldValue('contactUnLoad', data);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col flex="auto">
                              <Form.Item
                                hasFeedback
                                name={[field.name, 'phone']}
                              >
                                <Input placeholder="Телефон" />
                              </Form.Item>
                            </Col>
                            {fields.length > 1 && (
                              <Col
                                style={{
                                  display: 'flex',
                                  alignItems: 'baseline',
                                }}
                              >
                                <Button
                                  danger
                                  style={{ marginTop: '0px' }}
                                  type="dashed"
                                  icon={<CloseOutlined />}
                                  onClick={() => remove(field.name)}
                                />
                              </Col>
                            )}
                          </Row>
                        ))}
                        <Button
                          type="primary"
                          style={{ marginBottom: 3 }}
                          onClick={() => add()}
                          block
                        >
                          + Добавить контакт
                        </Button>
                      </div>
                    );
                  }}
                </Form.List>
              </Form.Item>

              <Row gutter={4}>
                <Col flex="auto">
                  <Form.Item
                    label="Морской перевозчик"
                    name="ship"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Select
                      disabled={!canAll}
                      showSearch
                      placeholder="Начните заполнять"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toUpperCase()
                          .includes(input.toUpperCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toUpperCase()
                          .localeCompare((optionB?.label ?? '').toUpperCase())
                      }
                      options={drivers}
                    />
                  </Form.Item>
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '6px',
                  }}
                >
                  <Tooltip title="Добавить перевозчика">
                    <Button
                      icon={<PlusOutlined />}
                      type="text"
                      disabled={!canAll}
                      onClick={() => {
                        setType(ClientType.TRANSPORT);
                        setOpenModal(true);
                      }}
                    />
                  </Tooltip>
                </Col>
              </Row>

              <Form.Item name="containerNumber" label="Номер Контейнера">
                <Input.TextArea autoSize disabled={!canAll} />
              </Form.Item>

              <Row gutter={[12, 12]}>
                <Col sm={24} md={12}>
                  <Form.Item name="mbl" label="MBL">
                    <Input disabled={!canAll} />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item name="hbl" label="HBL">
                    <Input disabled={!canAll} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col sm={24} md={8}>
                  <Form.Item name="etd" label="ETD">
                    <DatePicker
                      allowClear
                      picker="date"
                      disabled={!canAll}
                      style={{ width: '100%' }}
                      format={'DD MMM YYYY'}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={8}>
                  <Form.Item name="line" label="Линия">
                    <Input disabled={!canAll} />
                  </Form.Item>
                </Col>
                <Col sm={24} md={8}>
                  <Form.Item name="eta" label="ETA">
                    <DatePicker
                      allowClear
                      picker="date"
                      disabled={!canAll}
                      style={{ width: '100%' }}
                      format={'DD MMM YYYY'}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* <CanAccess resource="ships" action="fullInfo">
              <Col md={24}>
                <Divider plain orientation="left">
                  <Typography.Text style={{ color: '#8c8c8c' }}>
                    Заполнение Агентом
                  </Typography.Text>
                </Divider>

                <Row gutter={[8, 8]}>
                  <Col md={12}></Col>
                  <Col md={12}></Col>
                </Row>
              </Col>
            </CanAccess> */}
          </Row>

          <Form.Item label="Язык" name="language" hidden>
            <Input />
          </Form.Item>
        </Form>
      </Edit>
    </CanAccess>
  ) : (
    <Spin>Загрузка</Spin>
  );
};
