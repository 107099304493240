import React, { useState, useContext } from 'react';
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNotification,
  useSelect,
} from '@pankod/refine-core';
import {
  AutoComplete,
  Button,
  Col,
  Create,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
  useForm,
} from '@pankod/refine-antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

import 'react-mde/lib/styles/css/react-mde-all.css';
import { ICompanies, IOffer, IPicture, IUser } from 'interfaces';
import { Role } from '../../enums/role';
import { UserContext } from 'UserProvider';
import { Languages } from 'enums/languages.type';
import cargoList from 'constants/cargo';
import conditions from 'constants/condition';
import docs from 'constants/docs';
import { Firms } from 'enums/firms.type';
import transportTypes from 'constants/transport';
import { CurrencyType } from 'enums/currency.type';
import standartVariant from 'constants/standartVariant';
import vamas from 'constants/vama';
import ModalAddLender from 'components/modals/addCompanies';
import { ClientType } from 'enums/client.type';
import dayjs from 'dayjs';
import moment from 'moment';

const { Text } = Typography;
const { TextArea } = Input;
export const OfferCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, form } = useForm<IOffer>({
    // redirect: false,
    successNotification: (data: any, value, test) => {
      return {
        description: `Заявка создана под номером ${data.data.offerNumber}`,
        message: 'Просматривайте заявки на странице Заявок',
        type: 'success',
      };
    },
    errorNotification: {
      description: 'Заявка не создана',
      message: 'Возникли проблемы с сохранением в БД',
      type: 'error',
    },
  });
  const [language, setLanguage] = useState<Languages>(Languages.RU);

  const { options: firms, queryResult: firmsQuerryResult } = useSelect<
    ICompanies[]
  >({
    resource: 'companies/offerFirms',
    optionValue: 'uuid',
    optionLabel: 'orgName',
  });
  const { options: drivers, queryResult: driverQuerryResult } = useSelect<
    ICompanies[]
  >({
    resource: 'companies/offerDrivers',
    optionValue: 'uuid',
    optionLabel: 'orgName',
  });
  const { refetch: firmsRefetch } = firmsQuerryResult;
  const { refetch: driversRefetch } = driverQuerryResult;

  const cargoOption = cargoList[language].map((item) => {
    return { value: item };
  });
  const carOption = cargoList[language].map((item) => {
    return { value: item };
  });
  const brokerOption = [
    { value: 'Электронная декларация' },
    { value: 'Declaratia electronica' },
  ];

  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;

  const initialValuesTest = {
    offerLender: 5,
    thermoMode: '2dsad',
    characterCargo: 'Алкогольные напитки',
    mass: '1',
    volum: '1',
    adressLoad: [
      {
        adress: 'dsadsadas',
      },
    ],
    contactLoad: [
      {
        fio: 'dewda',
        phone: '3123123123',
      },
    ],
    dateLoadAdd: 1,
    // dateLoad: dayjs('2024-04-15T21:00:00.000Z'),
    dateUnLoadAdd: 2,
    dateUnLoad: dayjs('2024-04-16T21:00:00.000Z'),
    adressUnLoading: 'dasdsadasdasd',
    conditions: ['1) Контроль погрузки! '],
    documents: ['CMR', 'CMR/TIR'],
    offerDriver: 7,
    transportType: 'Автовоз',
    driverContact: [
      {
        fio: 'dsadasdas',
        phone: '312312312',
      },
    ],
    priceFirm: '370',
    priceDriver: '3',
    firmFrom: 7,
    firmTo: 10,
    driverFrom: 20,
    driverTo: 20,
    valutaryPay: '0',
    broker: 'Электронная декларация',
    vamaExport: 'dsadasdsa',
    vamaImport: 'Молдова, г.Бельцы',
    vamaPogran: 'Могилев-Подольский / Атаки ',
    way: 'dsadasdasdas',
    wayFrom: 'asdasd',
    wayTo: 'dsad',
    company: 'Real Cargo Industry',
    language: language,
  };
  const initialValues = {
    adressLoad: [null],
    contactLoad: [{ fio: null, phone: null }],
    driverContact: [{ fio: null, phone: null }],
    firmFrom: 7,
    firmTo: 10,
    driverFrom: 20,
    driverTo: 20,
    language: language,
  };

  const optionsDays: number[] = [];
  for (let i = 1; i <= 31; i++) {
    optionsDays.push(i);
  }

  const optionsCondition = conditions[language].map((condition) => {
    return { label: condition, value: condition };
  });

  const optionsDocs = docs[language].map((doc) => {
    return { label: doc, value: doc };
  });
  const vamaImport = standartVariant[language].map((vamaImp) => {
    return { label: vamaImp, value: vamaImp };
  });
  const vama = vamas[language].map((vamaImp) => {
    return { label: vamaImp, value: vamaImp };
  });

  const [openModalAdd, setOpenModal] = useState<boolean>(false);
  const [type, setType] = useState(ClientType.FIRM);
  return (
    <Create
      headerProps={{
        subTitle: '',
      }}
      headerButtons={[
        <Button
          children="Сохранить на устройстве"
          type="primary"
          size="small"
          disabled
        />,
        <Select
          onChange={(value) => {
            setLanguage(value);
            form.setFieldValue('language', value);
          }}
          defaultValue={language}
          variant="outlined"
          size="small"
        >
          <Select.Option value={Languages.RU} children={Languages.RU} />
          <Select.Option value={Languages.RO} children={Languages.RO} />
        </Select>,
      ]}
      title="Создать заявку"
      saveButtonProps={{ ...saveButtonProps, children: 'Оформить заявку' }}
    >
      <ModalAddLender
        open={openModalAdd}
        onCancel={() => setOpenModal(false)}
        refetch={type === ClientType.FIRM ? firmsRefetch : driversRefetch}
        type={type}
      />
      <Form
        {...formProps}
        initialValues={
          process.env.NODE_ENV === 'development'
            ? initialValuesTest
            : initialValues
        }
        layout="vertical"
      >
        <Row gutter={[24, 12]}>
          <Col md={12}>
            <Row gutter={4}>
              <Col flex="auto">
                <Form.Item
                  label="Заказчик"
                  name="offerLender"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Начните заполнять"
                    optionFilterProp="children"
                    virtual={true}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toUpperCase()
                        .includes(input.toUpperCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toUpperCase()
                        .localeCompare((optionB?.label ?? '').toUpperCase())
                    }
                    options={firms}
                  />
                </Form.Item>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '6px',
                }}
              >
                <Tooltip title="Добавить заказчика">
                  <Button
                    icon={<PlusOutlined />}
                    type="text"
                    onClick={() => {
                      setType(ClientType.FIRM);
                      setOpenModal(true);
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Температурный режим"
                  name="thermoMode"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Характер груза"
                  name="characterCargo"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <AutoComplete
                    options={cargoOption}
                    allowClear
                    filterOption={(inputValue, option) =>
                      option?.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Тоннаж"
                  name="mass"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <Form.Item name="addMass" noStyle initialValue={'T'}>
                        <Select size="small" style={{ width: 70 }}>
                          <Select.Option value={' '}> </Select.Option>
                          <Select.Option value="KG">KG</Select.Option>
                          <Select.Option value="T">T</Select.Option>
                        </Select>
                      </Form.Item>
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Объем"
                  name="volum"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <Form.Item name="addVolum" noStyle initialValue={'m3'}>
                        <Select size="small" style={{ width: 70 }}>
                          <Select.Option value={' '}> </Select.Option>
                          <Select.Option value="Ep">Ep</Select.Option>
                          <Select.Option value="m3">m3</Select.Option>
                          <Select.Option value="LDM">LDM</Select.Option>
                          <Select.Option value="Ft.">Ft.</Select.Option>
                        </Select>
                      </Form.Item>
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Адрес погрузки" required>
              <Form.List name="adressLoad">
                {(fields, { add, remove }, item) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {fields.map((field) => (
                        <Row gutter={8}>
                          <Col flex="auto">
                            <Form.Item
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: 'Пожалуйста, введите адрес погрузки',
                                },
                              ]}
                              name={[field.name, 'adress']}
                            >
                              <TextArea rows={1} />
                            </Form.Item>
                          </Col>
                          {fields.length > 1 && (
                            <Col
                              style={{
                                display: 'flex',
                                alignItems: 'baseline',
                              }}
                            >
                              <Button
                                danger
                                style={{ marginTop: '0px' }}
                                type="dashed"
                                icon={<CloseOutlined />}
                                onClick={() => remove(field.name)}
                              />
                            </Col>
                          )}
                        </Row>
                      ))}
                      <Button
                        type="dashed"
                        style={{ marginBottom: 3 }}
                        onClick={() => add()}
                        block
                      >
                        + Добавить адрес погрузки
                      </Button>
                    </div>
                  );
                }}
              </Form.List>
            </Form.Item>
            <Form.Item label="Контакт погрузки">
              <Form.List name="contactLoad">
                {(fields, { add, remove }, item) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {fields.map((field, idx) => (
                        <Row gutter={8}>
                          <Col flex="auto">
                            <Form.Item hasFeedback name={[field.name, 'fio']}>
                              <Input
                                placeholder="ФИО"
                                onPaste={async (e) => {
                                  e.preventDefault();
                                  let number =
                                    await navigator.clipboard.readText();
                                  let text =
                                    await navigator.clipboard.readText();
                                  number = number.replace(/[^+0-9]/g, '');
                                  text = text.replace(/[+0-9]/g, '');

                                  const data =
                                    form.getFieldValue('contactLoad');
                                  data[idx] = {
                                    fio: text.trim(),
                                    phone: number.trim(),
                                  };
                                  console.log(data);

                                  form.setFieldValue('contactLoad', data);
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col flex="auto">
                            <Form.Item hasFeedback name={[field.name, 'phone']}>
                              <Input placeholder="Телефон" />
                            </Form.Item>
                          </Col>
                          {fields.length > 1 && (
                            <Col
                              style={{
                                display: 'flex',
                                alignItems: 'baseline',
                              }}
                            >
                              <Button
                                danger
                                style={{ marginTop: '0px' }}
                                type="dashed"
                                icon={<CloseOutlined />}
                                onClick={() => remove(field.name)}
                              />
                            </Col>
                          )}
                        </Row>
                      ))}
                      <Button
                        type="dashed"
                        style={{ marginBottom: 3 }}
                        onClick={() => add()}
                        block
                      >
                        + Добавить контакт
                      </Button>
                    </div>
                  );
                }}
              </Form.List>
            </Form.Item>

            <Row gutter={12}>
              <Col xs={24} md={12}>
                <Form.Item label="Дата и время погрузки" required>
                  <Row gutter={0}>
                    <Col style={{ width: '80px' }}>
                      <Form.Item name="dateLoadAdd">
                        <Select>
                          <Select.Option value={null}> </Select.Option>
                          {optionsDays.map((option) => (
                            <Select.Option value={option}>
                              {option}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col flex="auto">
                      <Form.Item
                        name="dateLoad"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, заполните',
                          },
                        ]}
                        initialValue={dayjs()
                          .startOf('day')
                          .set('hour', 8)
                          .set('minute', 0)}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          format={'DD.MM.YYYY HH:mm'}
                          showTime={{ format: 'HH:mm' }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Дата выгрузки" required>
                  <Row gutter={0}>
                    <Col style={{ width: '80px' }}>
                      <Form.Item name="dateUnLoadAdd">
                        <Select>
                          <Select.Option value={null}> </Select.Option>
                          {optionsDays.map((option) => (
                            <Select.Option value={option}>
                              {option}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col flex="auto">
                      <Form.Item
                        name="dateUnLoad"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, заполните',
                          },
                        ]}
                      >
                        <DatePicker
                          format={'DD.MM.YYYY'}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Адрес выгрузки" name="adressUnLoading">
              <TextArea rows={1} />
            </Form.Item>
            <Form.Item
              label="Особые условия"
              rules={[
                {
                  required: true,
                  message: 'Пожалйста, заполните особые условия',
                },
              ]}
              name="conditions"
            >
              <Select mode="multiple" allowClear options={optionsCondition} />
            </Form.Item>
            <Form.Item
              label="Документы"
              rules={[
                {
                  required: true,
                  message: 'Пожалйста, заполните документы',
                },
              ]}
              name="documents"
            >
              <Select mode="multiple" allowClear options={optionsDocs} />
            </Form.Item>
            <Form.Item
              label="Компания"
              rules={[
                {
                  required: true,
                  message: 'Пожалйста, выберите компанию',
                },
              ]}
              name="company"
            >
              <Select>
                <Select.Option value={Firms.REAL}>{Firms.REAL}</Select.Option>
                <Select.Option value={Firms.RCI}>{Firms.RCI}</Select.Option>
                <Select.Option value={Firms.RCI_INVEST}>
                  {Firms.RCI_INVEST}
                </Select.Option>
                <Select.Option value={Firms.RCI_PMR}>
                  {Firms.RCI_PMR}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Row gutter={4}>
              <Col flex="auto">
                <Form.Item
                  label="Перевозчик"
                  name="offerDriver"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Начните заполнять"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toUpperCase()
                        .includes(input.toUpperCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toUpperCase()
                        .localeCompare((optionB?.label ?? '').toUpperCase())
                    }
                    options={drivers}
                  />
                </Form.Item>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '6px',
                }}
              >
                <Tooltip title="Добавить перевозчика">
                  <Button
                    icon={<PlusOutlined />}
                    type="text"
                    onClick={() => {
                      setType(ClientType.TRANSPORT);
                      setOpenModal(true);
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Тип авто"
                  name="transportType"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <AutoComplete allowClear>
                    {transportTypes[language].map((transport) => (
                      <AutoComplete.Option value={transport}>
                        {transport}
                      </AutoComplete.Option>
                    ))}
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Номер авто" name="carNumber" hasFeedback>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Контакт водителя">
              <Form.List name="driverContact">
                {(fields, { add, remove }, item) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {fields.map((field, idx) => (
                        <Row gutter={8}>
                          <Col flex="auto">
                            <Form.Item hasFeedback name={[field.name, 'fio']}>
                              <Input
                                placeholder="ФИО"
                                onPaste={async (e) => {
                                  e.preventDefault();
                                  let number =
                                    await navigator.clipboard.readText();
                                  let text =
                                    await navigator.clipboard.readText();
                                  number = number.replace(/[^+0-9]/g, '');
                                  text = text.replace(/[+0-9]/g, '');

                                  const data =
                                    form.getFieldValue('driverContact');
                                  data[idx] = {
                                    fio: text.trim(),
                                    phone: number.trim(),
                                  };
                                  console.log(data);

                                  form.setFieldValue('driverContact', data);
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col flex="auto">
                            <Form.Item hasFeedback name={[field.name, 'phone']}>
                              <Input placeholder="Телефон" />
                            </Form.Item>
                          </Col>
                          {fields.length > 1 && (
                            <Col
                              style={{
                                display: 'flex',
                                alignItems: 'baseline',
                              }}
                            >
                              <Button
                                danger
                                style={{ marginTop: '0px' }}
                                type="dashed"
                                icon={<CloseOutlined />}
                                onClick={() => remove(field.name)}
                              />
                            </Col>
                          )}
                        </Row>
                      ))}
                      <Button
                        type="dashed"
                        style={{ marginBottom: 3 }}
                        onClick={() => add()}
                        block
                      >
                        + Добавить контакт
                      </Button>
                    </div>
                  );
                }}
              </Form.List>
            </Form.Item>
            <Row gutter={12}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Цена заказчик"
                  name="priceFirm"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    addonAfter={
                      <Form.Item
                        name="currencyFirm"
                        initialValue={CurrencyType.EUR}
                        required
                        noStyle
                      >
                        <Select size="small" style={{ width: 100 }}>
                          <Select.Option value={CurrencyType.EUR}>
                            {CurrencyType.EUR}
                          </Select.Option>
                          <Select.Option value={CurrencyType.USD}>
                            {CurrencyType.USD}
                          </Select.Option>
                          <Select.Option value={CurrencyType.MDL}>
                            {CurrencyType.MDL}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Цена перевозчик"
                  name="priceDriver"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    addonAfter={
                      <Form.Item
                        name="currencyDriver"
                        initialValue={CurrencyType.EUR}
                        required
                        noStyle
                      >
                        <Select size="small" style={{ width: 100 }}>
                          <Select.Option value={CurrencyType.EUR}>
                            {CurrencyType.EUR}
                          </Select.Option>
                          <Select.Option value={CurrencyType.USD}>
                            {CurrencyType.USD}
                          </Select.Option>
                          <Select.Option value={CurrencyType.MDL}>
                            {CurrencyType.MDL}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col sm={24} md={12}>
                <Space>
                  <Space.Compact>
                    <Form.Item
                      label="Заказчик от"
                      name="firmFrom"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                      label="до"
                      name="firmTo"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </Space.Compact>
                </Space>
              </Col>
              <Col sm={24} md={12}>
                <Space>
                  <Space.Compact>
                    <Form.Item
                      label="Перевозчик от"
                      name="driverFrom"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                      label="до"
                      name="driverTo"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </Space.Compact>
                </Space>
              </Col>
            </Row>
            <Form.Item
              label="Валютный платеж"
              name="valutaryPay"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select>
                <Select.Option value="0">Платеж отсутствует</Select.Option>
                <Select.Option value="1">Полный</Select.Option>
                <Select.Option value="2">Половина</Select.Option>
                <Select.Option value="3">Треть</Select.Option>
                <Select.Option value="4">Оплата наличными</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Брокер" name="broker" hasFeedback>
              <AutoComplete
                options={brokerOption}
                allowClear
                filterOption={(inputValue, option) =>
                  option?.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
            <Form.Item label="Таможня экспорт" name="vamaExport" hasFeedback>
              <Input />
            </Form.Item>
            <Form.Item label="Таможня импорт" name="vamaImport" hasFeedback>
              <AutoComplete
                options={vamaImport}
                allowClear
                filterOption={(inputValue, option) =>
                  option?.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
            <Form.Item label="Погран переход" name="vamaPogran" hasFeedback>
              <AutoComplete
                options={vama}
                allowClear
                filterOption={(inputValue, option) =>
                  option?.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
            <Form.Item label="Маршрут" name="way" hasFeedback>
              <Input />
            </Form.Item>
            <Row gutter={12}>
              <Col sm={24} md={12}>
                <Form.Item label="Откуда" name="wayFrom" hasFeedback>
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item label="Куда" name="wayTo" hasFeedback>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Form.Item label="Язык" name="language" hidden>
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
