import {
  IResourceComponentsProps,
  useNavigation,
  useNotification,
  useOne,
} from '@pankod/refine-core';
import {
  Card,
  Col,
  Row,
  Spin,
  Statistic,
  StatisticProps,
  Table,
  TagField,
  TextField,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import {
  PhoneOutlined,
  CheckOutlined,
  AreaChartOutlined,
  BankOutlined,
  IssuesCloseOutlined,
} from '@ant-design/icons';

import { IPayment, IPicture, IUser, IVama } from 'interfaces';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from 'UserProvider';
import CountUp from 'react-countup';
import { Badge, TableColumnsType, Tag } from 'antd';
import moment from 'moment';
import { firmColor } from 'utility/getColor';
const { Text, Title } = Typography;

export const Dashboard: React.FC<IResourceComponentsProps> = () => {
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const { open: notify } = useNotification();

  const { list } = useNavigation();

  const { data: record, isLoading } = useOne<any>({
    resource: 'dashboard',
    id: me ? me.uuid : 0,
    liveMode: 'auto',
  });

  const formatter: StatisticProps['formatter'] = (value) => (
    <CountUp end={value as number} decimal="." separator="," />
  );

  const expandedRowRender = (value: any) => {
    const columns: TableColumnsType = [
      { title: 'Дата растаможки', dataIndex: 'dateVama', key: 'dateVama' },
      { title: 'Дата Оплаты', dataIndex: 'datePayment', key: 'datePayment' },
      {
        title: 'Номер заявки',
        dataIndex: 'offer',
        key: 'offer',
        render: (value) => (
          <Tooltip title={value.company}>
            <Tag
              color={firmColor(value.company)}
              style={{
                cursor: 'pointer',
                width: '50px',
                textAlign: 'center',
              }}
              onClick={() => {
                list(`blanks/offers?uuid=${value.uuid}`);
              }}
            >
              {value.offerNumber}
            </Tag>
          </Tooltip>
        ),
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (value) => <Badge status="processing" text={value} />,
      },
      {
        title: 'Сумма задолженности',
        dataIndex: 'summPayment',
        align: 'end',
        key: 'summPayment',
      },
    ];

    const data: any = value.data.map((el: IPayment, id: any) => {
      let summPayment = '';
      const datePayment = '';
      if (el.vamaLender.offer) {
        summPayment = `${el.vamaLender.offer.priceFirm} ${
          el.vamaLender.offer.currencyFirm
        } (${(
          +el.vamaLender.cursLender * +el.vamaLender.offer.priceFirm
        ).toLocaleString('ru-RU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} MDL)`;
      } else if (el.vamaLender.ship) {
        summPayment = `${el.vamaLender.ship.priceSell} ${
          el.vamaLender.ship.currencySell
        } (${(
          +el.vamaLender.cursLender * +el.vamaLender.ship.priceSell
        ).toLocaleString('ru-RU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} MDL)`;
      } else if (el.vamaLender.airplane) {
        summPayment = `${el.vamaLender.airplane.priceSell} ${
          el.vamaLender.airplane.currencySell
        } (${(
          +el.vamaLender.cursLender * +el.vamaLender.airplane.priceSell
        ).toLocaleString('ru-RU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} MDL)`;
      }
      return {
        key: id,
        status: el.status,
        summPayment: summPayment,
        dateVama: moment(el.vamaLender?.datePayment).format('DD.MM.YYYY'),
        datePayment:
          el?.vamaLender?.datePayment &&
          el?.vamaLender?.offer.firmFrom === el?.vamaLender?.offer.firmTo
            ? moment(el.vamaLender.datePayment)
                .add(el.vamaLender.offer.firmFrom, 'days')
                .format('DD.MM.YYYY')
            : `${moment(el?.vamaLender?.datePayment)
                .add(el.vamaLender?.offer.firmFrom, 'days')
                .format('DD.MM.YYYY')} - ${moment(el.vamaLender?.datePayment)
                .add(el.vamaLender?.offer.firmTo, 'days')
                .format('DD.MM.YYYY')}`,
        offer: el.vamaLender?.offer,
      };
    });

    return (
      <Table
        size="small"
        scroll={{ x: 'max-content' }}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    );
  };

  const columns: TableColumnsType = [
    { title: 'Компания', dataIndex: 'orgName', key: 'orgName' },
    {
      title: 'Сумма задолженности',
      dataIndex: 'summ',
      key: 'summ',
      render: (value) => (
        <TextField
          value={Number(value).toLocaleString('ru-RU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        />
      ),
    },
    {
      title: 'Количество заявок',
      dataIndex: 'data',
      key: 'data',
      render: (value, array) => <>{value.length}</>,
    },
  ];

  if (!record?.data) return <></>;
  const groupedData = Object.values(
    record?.data?.promiser.reduce((acc: any, obj: IPayment) => {
      let key = 0;
      if (obj.vamaLender.offer?.offerLender.uuid) {
        key = Number(obj.vamaLender.offer.offerLender.uuid);
      } else if (obj.vamaLender.ship?.client.uuid) {
        key = Number(obj.vamaLender.ship?.client.uuid);
      } else if (obj.vamaLender.airplane?.airplaneClient.uuid) {
        key = Number(obj.vamaLender.airplane?.airplaneClient.uuid);
      }

      if (!acc[key]) {
        let orgName = '';
        if (obj.vamaLender.offer?.offerLender.orgName) {
          orgName = obj.vamaLender.offer?.offerLender.orgName;
        } else if (obj.vamaLender.ship?.client?.orgName) {
          orgName = obj.vamaLender.ship.client.orgName;
        } else if (obj.vamaLender.airplane?.airplaneClient.orgName) {
          orgName = obj.vamaLender.airplane?.airplaneClient.orgName;
        }

        acc[key] = {
          uuid: key,
          key: key,
          summ: 0,

          orgName: orgName,
          data: [],
        };
      }

      if (obj.vamaLender.offer) {
        (acc[key].summ +=
          +obj.vamaLender.offer.priceFirm * +obj.vamaLender.cursLender),
          acc[key].data.push(obj);
      } else if (obj.vamaLender.ship) {
        (acc[key].summ +=
          +obj.vamaLender.ship.priceBuy * +obj.vamaLender.cursLender),
          acc[key].data.push(obj);
      } else if (obj.vamaLender.airplane) {
        (acc[key].summ +=
          +obj.vamaLender.airplane.priceBuy * +obj.vamaLender.cursLender),
          acc[key].data.push(obj);
      }
      return acc;
    }, {}),
  );

  return record?.data ? (
    <>
      <Row gutter={[12, 12]}>
        <Col md={6} sm={12} xs={24}>
          <Card bordered={false} hoverable>
            <Statistic
              loading={isLoading}
              title="Фирм Заказчиков"
              formatter={formatter}
              value={record?.data.firms}
              valueStyle={{
                color: record?.data.firms ? '#3f8600' : '#cf1322',
              }}
              prefix={
                record?.data.firms ? <BankOutlined /> : <IssuesCloseOutlined />
              }
            />
          </Card>
        </Col>
        <Col md={6} sm={12} xs={24}>
          <Card bordered={false} hoverable>
            <Statistic
              loading={isLoading}
              title="Заявок в этом месяце"
              formatter={formatter}
              value={record?.data.offer}
              valueStyle={{
                color: record?.data.offer ? '#3f8600' : '#cf1322',
              }}
              prefix={
                record?.data.offer ? (
                  <AreaChartOutlined />
                ) : (
                  <IssuesCloseOutlined />
                )
              }
            />
          </Card>
        </Col>
        <Col md={6} sm={12} xs={24}>
          <Card bordered={false} hoverable>
            <Statistic
              loading={isLoading}
              title="Звонков сегодня"
              formatter={formatter}
              value={record?.data.calendar}
              valueStyle={{
                color: !record?.data.calendar ? '#3f8600' : '#cf1322',
              }}
              prefix={
                !record?.data.calendar ? <CheckOutlined /> : <PhoneOutlined />
              }
            />
          </Card>
        </Col>
        <Col md={6} sm={12} xs={24}>
          <Card bordered={false} hoverable>
            <Statistic
              loading={isLoading}
              title="Должников"
              formatter={formatter}
              value={record?.data.promiser.length}
              valueStyle={{
                color: !record?.data.promiser.length ? '#3f8600' : '#cf1322',
              }}
              prefix={
                !record?.data.promiser.length ? (
                  <CheckOutlined />
                ) : (
                  <PhoneOutlined />
                )
              }
            />
          </Card>
        </Col>
        <Col xs={24}>
          <Card title="Должники" size="small" hoverable>
            <Table
              size="small"
              style={{ position: 'relative' }}
              loading={isLoading}
              columns={columns}
              expandable={{ expandedRowRender }}
              dataSource={groupedData}
              scroll={{ x: 'max-content' }}
            />
          </Card>
        </Col>
      </Row>
    </>
  ) : (
    <Spin>Загрузка</Spin>
  );
};
