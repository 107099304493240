import {
  Card,
  DatePicker,
  Descriptions,
  DescriptionsProps,
  Divider,
  Form,
  Input,
  Modal,
  Space,
  Tag,
  TextField,
  useForm,
  InputNumber,
} from '@pankod/refine-antd';
import { useNotification, useOne, useUpdate } from '@pankod/refine-core';
import { IUser, IVama } from 'interfaces';
import moment from 'moment';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { VamaStatus } from 'enums/vama.status.type';
import ImageDragAndDrop from 'components/imageDragAndDrop';
import { DocumentType } from 'enums/document.type';
import { Flex } from 'antd';

const { TextArea } = Input;

export default function ModalShipVama({
  open,
  close,
  me,
  uuid,
  refetch,
}: {
  open: boolean;
  close: any;
  me: IUser;
  uuid: number;
  refetch: any;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    data,
    refetch: refetchRecord,
    isFetched,
  } = useOne<IVama>({
    resource: 'vama',
    id: uuid,
  });
  // const { formProps, saveButtonProps, queryResult, form } = useForm<IVama>({
  //   id: uuid,
  //   resource: 'vama',
  //   successNotification: {
  //     description: t('translation.vama.complete'),
  //     message:
  //       'Для публикации предложения отправьте его на согласование, нажав Просмотр',
  //     type: 'success',
  //   },
  // });

  const { mutate, isLoading } = useUpdate();

  const { open: notify } = useNotification();
  const [form] = Form.useForm();

  const save = (eventForm: any) => {
    if (notify) {
      setLoading(true);
      const data = eventForm;
      try {
        const dataToSend = {
          ...data,
          datePayment: dayjs(data.datePayment).format('YYYY-MM-DD'),
          type: VamaStatus.WORK,
        };
        mutate({
          resource: 'vama',
          id: uuid,
          values: dataToSend,
          successNotification: {
            description: t('translation.vama.complete'),
            message: t('translation.vama.messages'),
            type: 'success',
          },
        });

        close();
        setLoading(false);
      } catch (error) {
        notify({
          message: 'Ошибка',
          type: 'error',
          description: 'Данные не были сохранены',
        });
        setLoading(false);
      }
    }
  };

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: t('translation.offerNumber'),
      children: <TextField value={data?.data.ship.uuid} />,
    },
    {
      key: '2',
      label: t('translation.offerLender'),
      children: data?.data.ship.client.orgName,
    },
    {
      key: '3',
      label: t('translation.offerDate'),
      children: moment(data?.data?.ship.createdAt).format('DD.MM.YYYY'),
    },
    {
      key: '4',
      label: t('translation.car_number'),
      children: <Tag>{data?.data.ship.containerNumber}</Tag>,
    },
  ];
  form.setFieldsValue({
    summ: data?.data.summ,
    datePayment: data?.data.datePayment ? dayjs(data?.data.datePayment) : null,
    commentLender: data?.data.commentLender,
    commentDriver: data?.data.commentDriver,
  });
  return (
    <Modal
      onCancel={close}
      open={open}
      onOk={form.submit}
      okButtonProps={{
        loading: loading,
        disabled: !data?.data.pictures || data?.data.pictures.length === 0,
      }}
      confirmLoading={isLoading}
      cancelButtonProps={{ loading: loading }}
      title={t('translation.vama.modal.title')}
    >
      <Descriptions
        size="small"
        column={1}
        title={
          <span style={{ fontWeight: 400 }}>
            {t('translation.vama.modal.description')}
          </span>
        }
        bordered
        items={items}
      />
      <Divider />
      <Form
        form={form}
        onFinish={save}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        labelAlign="left"
        initialValues={{ datePayment: null, uuid: uuid }}
      >
        <Form.Item label={t('translation.vama.summ')} name="summ">
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={t('translation.vama.dateDevama')}
          name="datePayment"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker
            style={{ width: '100%' }}
            format={{ type: 'mask', format: 'DD.MM.YYYY' }}
            picker="date"
          />
        </Form.Item>
        <Form.Item
          label={t('translation.vama.commentLender')}
          name="commentLender"
        >
          <TextArea rows={2} />
        </Form.Item>
        <Form.Item
          label={t('translation.vama.commentDriver')}
          name="commentDriver"
        >
          <TextArea rows={2} />
        </Form.Item>
        <Form.Item name="uuid" hidden>
          <Input />
        </Form.Item>
      </Form>
      <Flex justify="center">
        {data?.data && isFetched && (
          <ImageDragAndDrop
            type={DocumentType.DEVAMA}
            user={me}
            defaultList={data?.data.pictures}
            vama={data?.data}
            refetch={refetchRecord}
          />
        )}
      </Flex>
    </Modal>
  );
}
