import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useCan,
  useDelete,
  useGetIdentity,
  useNavigation,
  useSelect,
} from '@pankod/refine-core';
import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  CreateButton,
  DateField,
  DatePicker,
  DeleteButton,
  Descriptions,
  Dropdown,
  EditButton,
  EmailField,
  FilterDropdown,
  FilterDropdownProps,
  Form,
  getDefaultSortOrder,
  Input,
  List,
  MenuItemProps,
  MenuProps,
  message,
  NumberField,
  Row,
  Select,
  ShowButton,
  Space,
  Spin,
  Table,
  TableProps,
  TagField,
  TextField,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';

import {
  SolutionOutlined,
  FileAddOutlined,
  UserOutlined,
  DownOutlined,
  EllipsisOutlined,
  CalendarOutlined,
  MailOutlined,
  SignatureOutlined,
  WarningOutlined,
  DeleteOutlined,
  CalendarFilled,
  SnippetsOutlined,
  FileProtectOutlined,
} from '@ant-design/icons';
import { ICompanies, IOffer, IPicture, IUser, IVama } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, { CSSProperties, useContext, useState } from 'react';
import { UserContext } from 'UserProvider';
import moment from 'moment';
import { CurrencyType } from 'enums/currency.type';
import dayjs from 'dayjs';
import { Firms } from 'enums/firms.type';
import { VamaType } from 'enums/vama.type';
import { Flex } from 'antd';
import { DocumentType } from 'enums/document.type';
import { API_URL } from 'App';

const chechValPay = (valPay: string) => {
  switch (valPay) {
    case '0':
      return 'Отсутствует';
    case '1':
      return 'Полный';
    case '2':
      return 'Половина';
    case '3':
      return 'Треть';
    case '4':
      return 'Оплата наличными';
  }
};

export const CashList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;

  const initialFilter: CrudFilters = [
    { field: 'offer.user.uuid', operator: 'eq', value: me?.uuid },
    { field: 'datePayment', operator: 'ne', value: true },
    { field: 'vamaType', operator: 'eq', value: VamaType.EXPEDITION },
    {
      field: 'datePayment',
      operator: 'gte',
      value: `${dayjs().format('YYYY')}-${dayjs().format('MM')}-01`,
    },
    {
      field: 'datePayment',
      operator: 'lt',
      value: `${dayjs().format('YYYY')}-${dayjs(
        dayjs().add(1, 'months').toString(),
      ).format('MM')}-01`,
    },
  ];

  const {
    tableProps,
    sorter,
    tableQueryResult,
    setFilters,
    setPageSize,
    setCurrent,
  } = useTable<IVama>({
    resource: 'vama/cash',
    queryOptions: { retry: 5 },
    liveMode: 'auto',
    initialPageSize: 200,
    // initialSorter: [
    //   {
    //     field: 'createdAt',
    //     order: 'desc',
    //   },
    // ],
    initialFilter,
  });

  const { data } = tableQueryResult;

  const { options: optionUser } = useSelect<IUser[]>({
    resource: 'users/get/userList',
    optionLabel: 'label',
    optionValue: 'value',
    liveMode: 'auto',
    hasPagination: false,
  });
  const options: any[] = [{ value: '', label: 'Показать все' }, ...optionUser];
  const optionsFirms: any[] = [
    { value: '', label: 'Показать все' },
    { value: Firms.RCI, label: Firms.RCI },
    { value: Firms.REAL, label: Firms.REAL },
    { value: Firms.RCI_INVEST, label: Firms.RCI_INVEST },
    { value: Firms.RCI_PMR, label: Firms.RCI_PMR },
  ];

  const cashTotal = ({
    priceFirm,
    priceDriver,
    cursLender,
    cursDriver,
    valutaryPay,
    userPercent,
    lenderCurrency,
  }: {
    priceFirm: string;
    priceDriver: string;
    cursLender: string;
    cursDriver: string;
    valutaryPay: string;
    userPercent: string;
    lenderCurrency: CurrencyType;
  }) => {
    let val = 0,
      toBank = 0;
    switch (Number(valutaryPay)) {
      case 0:
        val = 0;
        break;
      case 1:
        val = 50;
        break;
      case 2:
        val = 25;
        break;
      case 3:
        val = 50 / 3;
        break;
      case 4:
        val = (Number(priceDriver) / 100) * 16;
        break;
      default:
        val = (Number(priceDriver) / 100) * 16;
        break;
    }

    if (lenderCurrency !== CurrencyType.MDL) {
      toBank = 5;
    }

    // let answ = '0';
    // Формула
    // ((заказчик - валютный платёж - в банк)*курс_заказчик - перевозчик*курс_перевозчик) * (коэффицент- штрафы)
    // Старая система подсчета
    // if (parseFloat(prop[3]) > parseFloat(prop[2])) {
    //   answ = (
    //     (prop[0] * prop[2] - (prop[1] + val + toBank) * prop[3]) *
    //     prop[5]
    //   ).toFixed(2);
    //   // console.log(val);
    // } else {
    //   answ = (
    //     ((prop[0] - val - toBank) * prop[2] - prop[1] * prop[3]) *
    //     prop[5]
    //   ).toFixed(2);
    // }

    if (+priceFirm === 0) return '0';

    return (
      ((+priceFirm - +val - +toBank) * +cursLender -
        +priceDriver * +cursDriver) *
      (+userPercent / 100)
    ).toFixed(2);
  };

  const setMonth = (value: any) => {
    const month = dayjs(value).format('MM');
    const monthNext = dayjs(value).add(1, 'month').format('MM');
    const year = dayjs(value).format('YYYY');
    const yearNext = dayjs(value).add(1, 'month').format('YYYY');

    setFilters([
      {
        field: 'datePayment',
        operator: 'gte',
        value: `${year}-${month}-01`,
      },
      {
        field: 'datePayment',
        operator: 'lt',
        value: `${yearNext}-${monthNext}-01`,
      },
    ]);
  };

  const totalInfo = () => {
    if (!data?.total) return { cash: 0, diff: 0, total: 0, bank: 0 };
    const records: IVama[] = data.data;
    let total = 0,
      cash = 0,
      bankUsd = 0,
      bankEur = 0;

    let val = 0,
      toBank = 0;

    records.map((record: IVama) => {
      switch (Number(record.offer.valutaryPay)) {
        case 0:
          val = 0;
          break;
        case 1:
          val = 50;
          break;
        case 2:
          val = 25;
          break;
        case 3:
          val = 50 / 3;
          break;
        case 4:
          val = (Number(record.offer.priceDriver) / 100) * 16;
          break;
        default:
          val = (Number(record.offer.priceDriver) / 100) * 16;
          break;
      }

      if (record.offer.currencyFirm !== CurrencyType.MDL) {
        toBank = 5;
      }

      total +=
        (+record.offer.priceFirm - +val - +toBank) * +record.cursLender -
        +record.offer.priceDriver * +record.cursDriver;
      cash +=
        ((+record.offer.priceFirm - +val - +toBank) * +record.cursLender -
          +record.offer.priceDriver * +record.cursDriver) *
        (+record.offer.userPercent / 100);

      if (record.offer.currencyFirm === CurrencyType.EUR) {
        bankEur += 5;
      } else if (record.offer.currencyFirm === CurrencyType.USD) {
        bankUsd += 5;
      }
    });
    return {
      cash,
      diff: total - cash,
      total,
      bank: `${bankUsd} д / ${bankEur} е`,
    };
  };

  return !tableProps.loading ? (
    <>
      <List
        headerProps={{
          extra: null,
        }}
      >
        <Row gutter={[12, 12]}>
          <Col xs={24} md={5}>
            <Select
              style={{ width: '100%' }}
              defaultValue={''}
              size="small"
              options={optionsFirms}
              onChange={(value) => {
                setFilters([
                  { field: 'offer.company', operator: 'eq', value: value },
                ]);
              }}
            />
          </Col>
          <Col xs={24} md={5}>
            <Select
              showSearch
              style={{ width: '100%' }}
              defaultValue={me?.uuid}
              size="small"
              options={options}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toUpperCase()
                  .includes(input.toLocaleUpperCase())
              }
              onChange={(value) => {
                setFilters([
                  { field: 'offer.user.uuid', operator: 'eq', value: value },
                ]);
              }}
            />
          </Col>
          <Col>
            <DatePicker
              picker="month"
              size="small"
              defaultValue={dayjs()}
              onChange={setMonth}
              format={'MMMM YYYY'}
            />
          </Col>
          <Col xs={24} md={7}>
            <Descriptions column={2} size="small">
              <Descriptions.Item label="Всего">
                {totalInfo() &&
                  totalInfo().total.toLocaleString('ru-RU', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
              </Descriptions.Item>
              <Descriptions.Item label="Разница">
                {totalInfo() &&
                  totalInfo().diff.toLocaleString('ru-RU', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
              </Descriptions.Item>
              <Descriptions.Item label="Баллы">
                {totalInfo() &&
                  totalInfo().cash.toLocaleString('ru-RU', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
              </Descriptions.Item>
              <Descriptions.Item label="В банк">
                {totalInfo().bank}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        <Table
          {...tableProps}
          rowKey="uuid"
          rowHoverable
          pagination={false}
          caption={
            <Typography.Text type="secondary" style={{ padding: '4px' }}>
              {`Всего в месяце : ${tableQueryResult.data?.total}`}
            </Typography.Text>
          }
          scroll={{ x: 'max-content' }}
          size="small"
        >
          <Table.Column
            dataIndex="datePayment"
            key="datePayment"
            title="Растаможка"
            render={(value) => (
              <TextField value={moment(value).format('DD.MM.YYYY')} />
            )}
            sorter
          />
          <Table.Column
            dataIndex="offer"
            key="offer.offerNumber"
            title="Заявка"
            fixed="left"
            render={(value: IOffer) => (
              <TextField value={`${value.offerNumber} (${value.company})`} />
            )}
            sorter
          />
          <Table.Column
            dataIndex="offer"
            key="offer.priceFirm"
            title="Заказчик (курс)"
            fixed="left"
            render={(value: IOffer, vama: IVama) => (
              <TextField
                value={`${Number(value.priceFirm).toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} ${value.currencyFirm} (${vama.cursLender})`}
              />
            )}
            sorter
          />
          <Table.Column
            dataIndex="offer"
            key="offer.priceDriver"
            title="Перевозчик (курс)"
            fixed="left"
            render={(value: IOffer, vama: IVama) => (
              <TextField
                value={`${Number(value.priceDriver).toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} ${value.currencyDriver} (${vama.cursDriver})`}
              />
            )}
            sorter
          />
          <Table.Column
            dataIndex="offer"
            key="offer.valutaryPay"
            title="Валютный платёж"
            fixed="left"
            render={(value: IOffer, vama: IVama) => (
              <TextField value={chechValPay(value.valutaryPay)} />
            )}
          />
          <Table.Column
            dataIndex="offer"
            key="offer.valutaryPay"
            title="Итоговые баллы"
            fixed="left"
            render={(value: IOffer, vama: IVama) => (
              <TextField
                type={
                  vama?.pictures &&
                  vama.pictures.filter((el) => el.type === DocumentType.CMR)
                    .length > 0
                    ? 'success'
                    : 'danger'
                }
                strong
                value={cashTotal({
                  priceFirm: value.priceFirm,
                  priceDriver: value.priceDriver,
                  cursLender: vama.cursLender,
                  cursDriver: vama.cursDriver,
                  valutaryPay: value.valutaryPay,
                  userPercent: value.userPercent,
                  lenderCurrency: value.currencyFirm,
                })}
              />
            )}
          />
          <Table.Column
            dataIndex="pictures"
            key="pictures"
            title="Документы"
            fixed="left"
            render={(value: IPicture[], vama: IVama) => (
              <Flex wrap="wrap" gap={'small'}>
                {value
                  .filter((el) => el.type === DocumentType.DEVAMA)
                  .map((el) => (
                    <Tooltip title={DocumentType.DEVAMA}>
                      <Button
                        type="default"
                        size="small"
                        onClick={() => window.open(API_URL + el.path, '_blank')}
                        icon={<SnippetsOutlined />}
                      ></Button>
                    </Tooltip>
                  ))}
                {value
                  .filter((el) => el.type === DocumentType.CMR)
                  .map((el) => (
                    <Tooltip title={DocumentType.CMR}>
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => window.open(API_URL + el.path, '_blank')}
                        icon={<FileProtectOutlined />}
                      ></Button>
                    </Tooltip>
                  ))}
              </Flex>
            )}
          />
          <Table.Column
            dataIndex="offer"
            key="offer.user"
            align="center"
            title="RCI"
            render={(value: IOffer) =>
              value.user && value.user.pictures?.path ? (
                <Tooltip
                  title={`${value.user.lastName} ${value.user.firstName}`}
                  placement="left"
                >
                  <Avatar
                    style={{ width: '50px', height: '50px' }}
                    src={`${process.env.REACT_APP_BACKEND_URL}${value.user.pictures.path}`}
                    alt={value.user.lastName}
                    icon={<UserOutlined />}
                  />
                  {/* <TextField value={value.uuid} /> */}
                </Tooltip>
              ) : (
                <Tooltip
                  title={`${value.user.lastName} ${value.user.firstName}`}
                  placement="left"
                >
                  <Avatar
                    style={{ width: '50px', height: '50px' }}
                    alt={value.user.lastName}
                    icon={<UserOutlined />}
                  />
                </Tooltip>
              )
            }
          />
        </Table>
      </List>
    </>
  ) : (
    <Spin>Загрузка</Spin>
  );
};
