import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useCan,
  useDelete,
  useGetIdentity,
  useNavigation,
  useNotification,
  useSelect,
  useUpdate,
} from '@pankod/refine-core';
import {
  Avatar,
  Badge,
  Button,
  Card,
  CreateButton,
  DateField,
  DeleteButton,
  Dropdown,
  EditButton,
  EmailField,
  FilterDropdown,
  FilterDropdownProps,
  Form,
  getDefaultSortOrder,
  Input,
  List,
  MenuItemProps,
  MenuProps,
  message,
  NumberField,
  Select,
  ShowButton,
  Space,
  Spin,
  Table,
  TableProps,
  Tag,
  TagField,
  TextField,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';

import {
  SolutionOutlined,
  FileAddOutlined,
  UserOutlined,
  DownOutlined,
  EllipsisOutlined,
  CalendarOutlined,
  MailOutlined,
  SignatureOutlined,
  WarningOutlined,
  DeleteOutlined,
  CalendarFilled,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { ICompanies, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, {
  ChangeEventHandler,
  CSSProperties,
  useContext,
  useState,
} from 'react';
import { Role } from '../../enums/role';
import { UserContext } from 'UserProvider';
import ModalAddLender from 'components/modals/addCompanies';
import { ClientType } from 'enums/client.type';
import ModalAddCalendar from 'components/calendar/addCalendar';
import { CalendarType } from 'enums/calendar.type';
import ModalEditCompany from 'components/modals/editCompanies';
import { tags } from 'enums/tags';
import { OptionProps } from 'antd/es/select';
import ModalEditUser from 'components/modals/editUser';
import ModalEditAssig from 'components/modals/editAssig';
import { axiosInstance } from 'App';
import { UserType } from 'enums/user.type';
import ModalHistoryCompanyAssig from 'components/modals/historyCompaniesAssig';
import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';

export const CompaniesList: React.FC<IResourceComponentsProps> = () => {
  const { list } = useNavigation();

  const { t } = useTranslation();
  const { open: notify } = useNotification();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const pageSizeOptions = Number(localStorage.getItem('pageSizeOptions')) || 10;

  const initialFilter: CrudFilters = [
    { field: 'user.uuid', operator: 'eq', value: me?.uuid },
  ];

  const {
    tableProps,
    sorter,
    tableQueryResult,
    setFilters,
    setPageSize,
    setCurrent,
    setSorter,
  } = useTable<ICompanies>({
    resource: 'companies/firms',
    queryOptions: { retry: 5 },

    initialPageSize: pageSizeOptions,
    initialFilter,
  });
  const { mutate: updateCompanies } = useUpdate({});

  const calendar: boolean =
    useCan({ resource: 'firms', action: 'calendar' }).data?.can || false;
  const edit: boolean =
    useCan({ resource: 'firms', action: 'edit' }).data?.can || false;
  const editOther: boolean =
    useCan({ resource: 'firms', action: 'editOther' }).data?.can || false;

  const changeUser: boolean =
    useCan({ resource: 'firms', action: 'changeUser' }).data?.can || false;
  const canDelete: boolean =
    useCan({ resource: 'firms', action: 'delete' }).data?.can || false;

  const { refetch } = tableQueryResult;
  const [openModalAdd, setOpenModal] = useState<boolean>(false);

  const [openModalEdit, setModalEdit] = useState<boolean>(false);
  const [openModalUserEdit, setOpenModalUserEdit] = useState<boolean>(false);
  const [openModalAssigEdit, setOpenModalAssigEdit] = useState<boolean>(false);
  const [openModalAddCalendar, setOpenModalCalendar] = useState<boolean>(false);

  const deleteUser = async (value: any, type: CalendarType) => {
    if (notify) {
      try {
        const response = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/companies/delete/firm',
          {
            companie: value.uuid,
            user: type === CalendarType.ASIG ? value.assig : value.user,
          },
        );
        notify({
          message: 'Заказчик был отвязан от сотрудника',
          description: 'Успешно!',
          type: 'success',
          key: '2',
          undoableTimeout: 20000,
        });
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: 'Возникла проблема , сообщите сис-админу :' + e,
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };

  const items = (value: any): MenuProps['items'] => {
    if (!me) return [];

    switch (me?.role) {
      case Role.MULTIMODAL:
        return me?.uuid === value?.user?.uuid
          ? [
              {
                label: 'Добавить в календарь',
                key: '1',
                icon: <CalendarOutlined />,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setOpenModalCalendar(true);
                },
                disabled: !calendar,
              },
              {
                label: 'Копировать Email',
                key: '2',
                disabled: true,
                icon: <MailOutlined />,
              },
              {
                // label: 'Редактировать',
                key: '3',
                // icon: <UserOutlined />,
                type: 'divider',
              },
              {
                label: 'Редактировать',
                key: '4',
                disabled: !edit,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setModalEdit(true);
                },
                icon: <SignatureOutlined />,
              },
              {
                label: 'Блокировать',
                key: '5',
                icon: <WarningOutlined />,
                danger: true,
                disabled: true,
              },
              {
                key: '6',
                type: 'divider',
              },
              {
                label: 'Сменить логиста',
                key: '7',
                icon: <UserOutlined />,
                disabled: !canDelete,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setOpenModalUserEdit(true);
                },
              },
              {
                label: 'Удалить логиста',
                key: '8',
                icon: <DeleteOutlined />,

                danger: true,
                onClick: () => {
                  deleteUser(value, CalendarType.LOGISTIC);
                  refetch();
                },
                disabled: !me.canEdit,
              },
            ]
          : [
              {
                label: 'Сменить логиста',
                key: '8',
                disabled:
                  me?.role && [Role.ADMIN, Role.DIRECTOR].includes(me.role)
                    ? false
                    : !value.user
                    ? false
                    : true,
                icon: <UserOutlined />,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setOpenModalUserEdit(true);
                },
              },
            ];
      case Role.MANAGER:
        return me?.uuid === value?.user?.uuid
          ? [
              {
                label: 'Добавить в календарь',
                key: '1',
                icon: <CalendarOutlined />,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setOpenModalCalendar(true);
                },
                disabled: !calendar,
              },
              {
                label: 'Копировать Email',
                key: '2',
                disabled: true,
                icon: <MailOutlined />,
              },
              {
                // label: 'Редактировать',
                key: '3',
                // icon: <UserOutlined />,
                type: 'divider',
              },
              {
                label: 'Редактировать',
                key: '4',
                disabled: !edit,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setModalEdit(true);
                },
                icon: <SignatureOutlined />,
              },
              {
                label: 'Блокировать',
                key: '5',
                icon: <WarningOutlined />,
                danger: true,
                disabled: true,
              },
              {
                key: '6',
                type: 'divider',
              },
              {
                label: 'Сменить логиста',
                key: '7',
                icon: <UserOutlined />,
                disabled: !canDelete,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setOpenModalUserEdit(true);
                },
              },
              {
                label: 'Удалить логиста',
                key: '8',
                icon: <DeleteOutlined />,

                danger: true,
                onClick: () => {
                  deleteUser(value, CalendarType.LOGISTIC);
                  refetch();
                },
                disabled: !me.canEdit,
              },
            ]
          : [
              {
                label: 'Сменить логиста',
                key: '8',
                disabled:
                  me?.role && [Role.ADMIN, Role.DIRECTOR].includes(me.role)
                    ? false
                    : !value.user
                    ? false
                    : true,
                icon: <UserOutlined />,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setOpenModalUserEdit(true);
                },
              },
            ];

      case Role.MANAGER_ASIG:
        return me?.uuid === value?.assig?.uuid
          ? [
              {
                label: 'Добавить в календарь',
                key: '1',
                icon: <CalendarOutlined />,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setOpenModalCalendar(true);
                },
                disabled: !calendar,
              },
              {
                label: 'Копировать Email',
                key: '2',
                disabled: true,
                icon: <MailOutlined />,
              },
              {
                // label: 'Редактировать',
                key: '3',
                // icon: <UserOutlined />,
                type: 'divider',
              },
              {
                label: 'Редактировать',
                key: '4',
                disabled: !edit,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setModalEdit(true);
                },
                icon: <SignatureOutlined />,
              },
              {
                label: 'Блокировать',
                key: '5',
                icon: <WarningOutlined />,
                danger: true,
                disabled: true,
              },

              {
                key: '6',
                type: 'divider',
              },
              {
                label: 'Сменить страховщика',
                key: '7',
                disabled:
                  me?.role && [Role.ADMIN, Role.DIRECTOR].includes(me.role)
                    ? false
                    : true,
                icon: <UserOutlined />,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setOpenModalAssigEdit(true);
                },
              },
            ]
          : [
              {
                label: 'Сменить страховщика',
                key: '8',
                disabled:
                  me?.role && [Role.ADMIN, Role.DIRECTOR].includes(me.role)
                    ? false
                    : !value.assig
                    ? false
                    : true,
                icon: <UserOutlined />,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setOpenModalAssigEdit(true);
                },
              },
            ];

      default:
        return me?.uuid === value?.user?.uuid
          ? [
              {
                label: 'Добавить в календарь',
                key: '1',
                icon: <CalendarOutlined />,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setOpenModalCalendar(true);
                },
                disabled: !calendar,
              },
              {
                label: 'Копировать Email',
                key: '2',
                disabled: true,
                icon: <MailOutlined />,
              },
              {
                // label: 'Редактировать',
                key: '3',
                // icon: <UserOutlined />,
                type: 'divider',
              },
              {
                label: 'Редактировать',
                key: '4',
                disabled: !edit,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setModalEdit(true);
                },
                icon: <SignatureOutlined />,
              },
              {
                label: 'Блокировать',
                key: '5',
                icon: <WarningOutlined />,
                danger: true,
                disabled: true,
              },

              {
                key: '6',
                type: 'divider',
              },
              {
                label: 'Сменить логиста',
                key: '7',
                icon: <UserOutlined />,
                disabled: !changeUser,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setOpenModalUserEdit(true);
                },
              },
              {
                label: 'Удалить логиста',
                key: '8',
                icon: <DeleteOutlined />,
                danger: true,
                onClick: () => {
                  deleteUser(value, CalendarType.LOGISTIC);
                  refetch();
                },
                disabled: !edit || !value.user,
              },
              {
                key: '9',
                type: 'divider',
              },
              {
                label: 'Сменить страховщика',
                key: '10',
                disabled:
                  me?.role && [Role.ADMIN, Role.DIRECTOR].includes(me.role)
                    ? false
                    : true,
                icon: <UserOutlined />,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setOpenModalAssigEdit(true);
                },
              },
              {
                label: 'Удалить страховщика',
                key: '11',
                icon: <DeleteOutlined />,
                danger: true,
                onClick: () => {
                  deleteUser(value, CalendarType.ASIG);
                  refetch();
                },
                disabled: !edit || !value.assig,
              },
            ]
          : [
              {
                label: 'Добавить в календарь',
                key: '1',
                icon: <CalendarOutlined />,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setOpenModalCalendar(true);
                },
                disabled: !calendar,
              },
              {
                label: 'Копировать Email',
                key: '2',
                icon: <MailOutlined />,
              },
              {
                // label: 'Редактировать',
                key: '3',
                // icon: <UserOutlined />,
                type: 'divider',
              },
              {
                label: 'Редактировать',
                key: '4',
                icon: <SignatureOutlined />,
                disabled: !editOther,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setModalEdit(true);
                },
              },
              {
                label: 'Блокировать',
                key: '5',
                icon: <WarningOutlined />,
                danger: true,
                disabled: !editOther,
              },

              {
                key: '6',
                type: 'divider',
              },
              {
                label: 'Сменить логиста',
                key: '7',
                disabled:
                  me?.role &&
                  [Role.ADMIN, Role.DIRECTOR, Role.CONTROL].includes(me.role)
                    ? false
                    : true,
                icon: <UserOutlined />,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setOpenModalUserEdit(true);
                },
              },
              {
                label: 'Удалить логиста',
                key: '8',
                icon: <DeleteOutlined />,
                danger: true,
                disabled: !edit || !value.user,
                onClick: () => {
                  deleteUser(value, CalendarType.LOGISTIC);
                  refetch();
                },
              },
              {
                key: '9',
                type: 'divider',
              },
              {
                label: 'Сменить страховщика',
                key: '10',
                disabled:
                  me?.role && [Role.ADMIN, Role.DIRECTOR].includes(me.role)
                    ? false
                    : true,
                icon: <UserOutlined />,
                onClick: () => {
                  setSelectedUuid(value.uuid);
                  setOpenModalAssigEdit(true);
                },
              },
              {
                label: 'Удалить страховщика',
                key: '11',
                icon: <DeleteOutlined />,
                danger: true,
                onClick: () => {
                  deleteUser(value, CalendarType.ASIG);
                  refetch();
                },
                disabled: !edit || !value.assig,
              },
            ];
    }
  };

  const menuProps = (value: any) => {
    const itms = items(value);

    return { items: itms };
  };

  const { options: optionUser } = useSelect<IUser[]>({
    resource: 'users/get/userList',
    optionLabel: 'label',
    optionValue: 'value',
    liveMode: 'auto',
    hasPagination: false,
  });
  const options: any[] = optionUser;

  const addOption = [
    { label: 'Показать все', value: 0 },
    { label: 'Без менеджера', value: -1 },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [openAssigHistory, setOpenAssigHistory] = useState<boolean>(false);

  const companiesMenuItem = (value: any): MenuProps['items'] => [
    {
      key: '1',
      label: (
        <div onClick={() => list(`blanks/offers?companies=${value}`)}>
          История логистики
        </div>
      ),
    },
    {
      key: '2',
      disabled: true,
      label: <>История морских перевозок</>,
    },
    {
      key: '3',
      disabled: true,
      label: <>История ж/д перевозок</>,
    },
  ];

  const [form] = Form.useForm();

  const handleChandeFinder = (event: any) => {
    const {
      target: { value },
    } = event;

    if (value.length < 2) {
      form.setFieldValue('rci_manager', me?.uuid);
      setFilters([
        { field: 'orgName', operator: 'eq', value: null },
        { field: 'user.uuid', operator: 'eq', value: me?.uuid },
      ]);
      return;
    }

    form.setFieldValue('rci_manager', 0);

    setFilters([
      { field: 'orgName', operator: 'eq', value: value },
      { field: 'user.uuid', operator: 'eq', value: null },
    ]);

    console.log(value);
  };

  return !tableProps.loading ? (
    <>
      {selectedUuid && openAssigHistory && (
        <CanAccess resource="firms" action="history">
          <ModalHistoryCompanyAssig
            onCancel={() => setOpenAssigHistory(false)}
            open={openAssigHistory}
            uuid={selectedUuid}
          />
        </CanAccess>
      )}
      <CanAccess resource="firms" action="changeUser">
        {openModalUserEdit && me && (
          <ModalEditUser
            open={openModalUserEdit}
            onCancel={() => setOpenModalUserEdit(false)}
            refetch={refetch}
            uuid={Number(selectedUuid)}
            me={me}
            array={selectedRowKeys.length === 0 ? [] : selectedRowKeys}
          />
        )}
      </CanAccess>
      {openModalAssigEdit && (
        <ModalEditAssig
          open={openModalAssigEdit}
          onCancel={() => setOpenModalAssigEdit(false)}
          refetch={refetch}
          uuid={Number(selectedUuid)}
          array={selectedRowKeys.length === 0 ? [] : selectedRowKeys}
        />
      )}
      <CanAccess resource="firms" action="create">
        {openModalAdd && (
          <ModalAddLender
            open={openModalAdd}
            onCancel={() => setOpenModal(false)}
            refetch={refetch}
            type={ClientType.FIRM}
          />
        )}
      </CanAccess>
      <CanAccess resource="firms" action="edit">
        {openModalEdit && (
          <ModalEditCompany
            open={openModalEdit}
            onCancel={() => setModalEdit(false)}
            refetch={refetch}
            uuid={Number(selectedUuid)}
            me={me ? me : undefined}
          />
        )}
      </CanAccess>
      {openModalAddCalendar && (
        <ModalAddCalendar
          open={openModalAddCalendar}
          onCancel={() => setOpenModalCalendar(false)}
          refetch={refetch}
          type={CalendarType.LOGISTIC}
          uuid={Number(selectedUuid)}
        />
      )}
      <List
        headerProps={{
          extra: [
            selectedRowKeys?.length !== 0 && (
              <Button
                onClick={() => setOpenModalUserEdit(true)}
                type={'primary'}
                children="Присвоить логисту"
              />
            ),
            selectedRowKeys?.length !== 0 && (
              <Button
                onClick={() => setOpenModalAssigEdit(true)}
                type={'primary'}
                children="Присвоить страховщику"
              />
            ),

            <Button
              onClick={() => setOpenModal(true)}
              type={'primary'}
              children="Добавить фирму"
            />,
          ],
        }}
      >
        <Flex vertical gap={12}>
          <Form layout="horizontal" form={form}>
            <Flex gap={12}>
              <Form.Item name="rci_manager">
                <Select
                  showSearch
                  style={{ width: '200px' }}
                  defaultValue={me?.uuid}
                  size="small"
                  options={[...addOption, ...options]}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toUpperCase()
                      .includes(input.toLocaleUpperCase())
                  }
                  onChange={(value) => {
                    setFilters([
                      { field: 'user.uuid', operator: 'eq', value: value },
                    ]);
                  }}
                />
              </Form.Item>
              <Form.Item name="finder">
                <Tooltip
                  title={'Поиск проходит если введено больше 2х символов'}
                >
                  <Input
                    size="small"
                    title="Поиск"
                    style={{ width: 350 }}
                    allowClear
                    placeholder="Введите для поиска : Название компании"
                    onChange={handleChandeFinder}
                  />
                </Tooltip>
              </Form.Item>
            </Flex>
          </Form>
          <Table
            {...tableProps}
            rowKey="uuid"
            rowHoverable
            pagination={{
              ...tableProps.pagination,
              position: ['bottomRight'],
              showSizeChanger: true,
              pageSizeOptions: ['10', '50', '100', '200'],
              size: 'small',
            }}
            showHeader
            caption={
              <Typography.Text type="secondary" style={{ padding: '4px' }}>
                {`Всего компаний : ${tableQueryResult.data?.total}`}
              </Typography.Text>
            }
            // onChange={(value) => {
            //   if (value.pageSize) {
            //     localStorage.setItem('pageSizeOptions', String(value.pageSize));
            //     setPageSize(value.pageSize);
            //   }
            //   if (value.current) {
            //     setCurrent(value.current);
            //   }
            //   console.log(value);
            // }}
            size="small"
            rowSelection={
              me?.role &&
              [Role.ADMIN, Role.DIRECTOR, Role.SYSTEM].includes(me.role)
                ? rowSelection
                : undefined
            }
          >
            <Table.Column
              dataIndex="orgName"
              key="orgName"
              title="Название"
              render={(value, record: any) => (
                <>
                  <Dropdown
                    menu={{ items: companiesMenuItem(record.uuid) }}
                    placement="bottomRight"
                    arrow
                    disabled={
                      +record.offerLender?.length + +record.shipClient.length <
                      1
                    }
                    trigger={['click']}
                  >
                    <Badge
                      count={
                        +record.offerLender?.length + +record.shipClient.length
                      }
                      style={{ background: 'green' }}
                    >
                      <Tag
                        bordered
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          cursor: 'pointer',
                        }}
                        color={'#154468'}
                        onClick={() => {
                          if (me?.role === Role.MANAGER_ASIG) {
                            setOpenAssigHistory(true);
                            setSelectedUuid(record.uuid);
                          }
                        }}
                      >
                        <div
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {value}
                        </div>
                      </Tag>
                    </Badge>
                  </Dropdown>
                </>
              )}
              defaultSortOrder={getDefaultSortOrder('orgName', sorter)}
              sorter
              // filterDropdown={(props: FilterDropdownProps) => {
              //   return (
              //     <FilterDropdown {...props}>
              //       <Input />
              //     </FilterDropdown>
              //   );
              // }}
              // filterSearch={true}
            />

            <Table.Column
              dataIndex="contact"
              key="contact"
              title="Контакты"
              render={(value, record: ICompanies) => {
                if (!value || !me) return <></>;

                if (
                  me &&
                  me.role &&
                  ((record.user && record.user.uuid === me.uuid) ||
                    (record.assig && record.assig.uuid === me.uuid) ||
                    [
                      Role.ACCOUNTANT,
                      Role.DIRECTOR,
                      Role.ADMIN,
                      Role.MANAGER_BIG,
                      Role.CONTROL,
                      Role.SYSTEM,
                    ].includes(me.role))
                ) {
                  const array = JSON.parse(value);

                  return (
                    <Space size={4} direction="vertical">
                      {array.map((contact: any) => {
                        if (contact)
                          return (
                            <div key={contact}>
                              {contact.name && (
                                <TextField value={contact.name} />
                              )}{' '}
                              {contact.phone && (
                                <TextField value={contact.phone} />
                              )}
                            </div>
                          );
                      })}
                    </Space>
                  );
                } else {
                  return <>***</>;
                }
              }}
            />
            <Table.Column
              dataIndex="tags"
              key="tags"
              title="Теги"
              render={(value) => (
                <TextField value={value ? JSON.parse(value).join(' ') : ''} />
              )}
              defaultSortOrder={getDefaultSortOrder('tags', sorter)}
              filterDropdown={(props) => (
                <FilterDropdown {...props}>
                  <Select
                    style={{ minWidth: 200 }}
                    mode="multiple"
                    placeholder="Выберите ТЕГИ"
                    options={tags?.map((tag) => ({
                      label: tag.value,
                      value: tag.value,
                    }))}
                    onSelect={(selectedTags) => {
                      props.confirm();
                    }}
                    onDeselect={props.confirm}
                  />
                </FilterDropdown>
              )}
            />
            <Table.Column
              dataIndex="comment"
              key="comment"
              title="Комментарии"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="user"
              key="user"
              title="Логист"
              width={80}
              hidden={me?.role === Role.MANAGER_ASIG}
              align="center"
              render={(value) =>
                value ? (
                  <Tooltip
                    title={`${value.lastName} ${value.firstName}`}
                    placement="left"
                  >
                    <Avatar
                      style={{ width: '50px', height: '50px' }}
                      src={`${process.env.REACT_APP_BACKEND_URL}${value.pictures?.path}`}
                      alt={value.lastName}
                      icon={<UserOutlined />}
                    />
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            />
            <Table.Column
              dataIndex="assig"
              key="assig"
              title="Страховщик"
              hidden={me?.role === Role.MANAGER}
              width={80}
              align="center"
              render={(value) =>
                value ? (
                  <Tooltip
                    title={`${value.lastName} ${value.firstName}`}
                    placement="left"
                  >
                    <Avatar
                      style={{ width: '50px', height: '50px' }}
                      src={`${process.env.REACT_APP_BACKEND_URL}${value.pictures?.path}`}
                      alt={value.lastName}
                      icon={<UserOutlined />}
                    />
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            />
            <Table.Column
              dataIndex="calendar"
              key="calendar"
              title=""
              width={60}
              align="center"
              render={(value: any[]) =>
                value.length !== 0 ? (
                  <>
                    <Badge count={value.length} style={{ background: 'green' }}>
                      <Avatar
                        shape="square"
                        style={{ background: 'transparent', color: '#164468' }}
                        icon={<CalendarOutlined />}
                      />
                    </Badge>
                  </>
                ) : (
                  <></>
                )
              }
            />

            <Table.Column
              key="activity"
              dataIndex="activity"
              title=""
              render={(activity, value: any) => (
                <>
                  {Boolean(activity) && (
                    <Tag
                      closeIcon={
                        <CloseCircleOutlined style={{ color: '#fff' }} />
                      }
                      color={'#154468'}
                      onClose={() => {
                        updateCompanies({
                          resource: 'companies/updateConfirm',
                          id: value.uuid,
                          values: { activity: null },
                          successNotification: {
                            description: t('update.titleSuccess'),
                            message: t('update.textSuccess'),
                            type: 'success',
                          },
                          errorNotification: {
                            description: t('error.createText'),
                            message: 'Возникла ошибка',
                            type: 'error',
                          },
                        });
                        console.log(value);
                      }}
                    >
                      {activity}
                    </Tag>
                  )}
                </>
              )}
            />
            <Table.Column
              key="action"
              title=""
              fixed="right"
              width={20}
              render={(_, value: any) => (
                <>
                  <Dropdown
                    menu={menuProps(value)}
                    arrow
                    trigger={['click']}
                    placement="bottomLeft"
                    destroyPopupOnHide
                  >
                    <Button
                      icon={<EllipsisOutlined />}
                      type="dashed"
                      shape="circle"
                    />
                  </Dropdown>
                </>
              )}
            />
          </Table>
        </Flex>
      </List>
    </>
  ) : (
    <Spin>Загрузка</Spin>
  );
};
