import { useForm } from '@pankod/refine-antd';
import { useDelete } from '@pankod/refine-core';
import { Button, DatePicker, Form, Grid, Input, Modal, Spin } from 'antd';
import dayjs from 'dayjs';
import { CalendarType } from 'enums/calendar.type';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ModalViewCalendarRecord = ({
  open,
  close,
  uuid,
  refetch,
}: {
  open: boolean;
  close: () => void;
  uuid?: number;
  refetch: () => void;
}) => {
  const { mutate: deleteRecord } = useDelete();
  const { t } = useTranslation();
  const { queryResult, form, formProps, saveButtonProps, id, formLoading } =
    useForm({
      resource: 'calendar',
      action: 'edit',
      id: uuid,
      redirect: false,
      onMutationSuccess: () => {
        close();

        // form.resetFields();
        // refetch();
      },
      successNotification: {
        description: t('calendar.create'),
        message: t('calendar.createText'),
        type: 'success',
      },
      errorNotification: {
        description: 'Запись не создана',
        message: 'Возникла ошибка',
        type: 'error',
      },
    });

  useEffect(() => {
    if (queryResult?.data?.data) {
      form.setFieldsValue({
        dateCall: dayjs(queryResult?.data?.data.dateCall),
        text: queryResult?.data?.data.text,
        title: queryResult?.data?.data.title,
      });
    }
  }, [queryResult?.data?.data]);

  return (
    <Modal
      open={open}
      onCancel={close}
      footer={[
        <Button size="small" onClick={() => close()}>
          Отмена
        </Button>,
        <Button
          size="small"
          type="primary"
          danger
          onClick={() =>
            deleteRecord(
              {
                resource: 'calendar',
                id: queryResult?.data?.data.uuid,
                successNotification: {
                  description: t('calendar.delete'),
                  message: t('calendar.createText'),
                  type: 'success',
                },
                errorNotification: {
                  description: 'Запись не удалена',
                  message: 'Возникла ошибка',
                  type: 'error',
                },
              },
              {
                onSuccess: () => {
                  close();
                },
              },
            )
          }
        >
          Удалить
        </Button>,
        <Button size="small" type="primary" {...saveButtonProps}>
          Сохранить
        </Button>,
      ]}
      okButtonProps={saveButtonProps}
      title="Редактировать запись"
    >
      <Spin spinning={formLoading}>
        <Form
          form={form}
          {...formProps}
          initialValues={{ dateCall: dayjs(), title: '', text: '' }}
          layout="vertical"
        >
          <Form.Item
            name="dateCall"
            label="Заголовок"
            rules={[{ required: true }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={'DD.MM.YYYY'}
              defaultValue={dayjs('2015-01-01', 'YYYY-MM-DD')}
            />
          </Form.Item>
          <Form.Item
            name="title"
            label="Заголовок"
            rules={[{ required: true }]}
          >
            <Input placeholder="Заголовок" />
          </Form.Item>
          <Form.Item name="text" label="Содержимое">
            <Input.TextArea placeholder="Текст записи" />
          </Form.Item>
          <Form.Item name="type" noStyle initialValue={CalendarType.SHIP}>
            <Input hidden />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalViewCalendarRecord;
