import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useCan,
  useDelete,
  useGetIdentity,
  useNavigation,
  useSelect,
  useUpdate,
} from '@pankod/refine-core';
import {
  Avatar,
  Button,
  Col,
  CreateButton,
  DateField,
  DatePicker,
  DeleteButton,
  Dropdown,
  EditButton,
  EmailField,
  FilterDropdown,
  FilterDropdownProps,
  Form,
  getDefaultSortOrder,
  Input,
  List,
  MenuItemProps,
  MenuProps,
  message,
  NumberField,
  Row,
  Select,
  ShowButton,
  Space,
  Spin,
  Table,
  Tag,
  TagField,
  TextField,
  Tooltip,
  useTable,
} from '@pankod/refine-antd';

import {
  EllipsisOutlined,
  SignatureOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  IssuesCloseOutlined,
  ClockCircleOutlined,
  StarFilled,
  StarOutlined,
  PhoneFilled,
} from '@ant-design/icons';
import { ICalendar, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, { CSSProperties, useContext, useState } from 'react';
import { UserContext } from 'UserProvider';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ModalAddCalendar from 'components/calendar/addCalendar';
import { CalendarType } from 'enums/calendar.type';
import ModalEditCalendar from 'components/calendar/editCalendar';

export const CalendarList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const pageSizeOptions = Number(localStorage.getItem('pageSizeOptions')) || 10;
  const [form] = Form.useForm();

  const [showFavorites, setShowFavorites] = useState<boolean>(false);
  const initialFilter: CrudFilters = [
    { field: 'calendars.favorite', operator: 'eq', value: showFavorites },
    { field: 'calendars.type', operator: 'eq', value: CalendarType.LOGISTIC },
    { field: 'user.uuid', operator: 'eq', value: me?.uuid },
  ];

  const {
    tableProps,
    sorter,
    tableQueryResult,
    setFilters,
    setPageSize,
    setSorter,
    setCurrent,
  } = useTable<ICalendar>({
    queryOptions: { retry: 5 },
    initialSorter: [
      {
        field: 'calendars.dateCall',
        order: 'asc',
      },
    ],
    initialPageSize: pageSizeOptions,
    initialFilter,
  });

  const edit: boolean =
    useCan({ resource: 'firms', action: 'edit' }).data?.can || false;
  const changeUser: boolean =
    useCan({ resource: 'firms', action: 'changeUser' }).data?.can || false;

  const { refetch } = tableQueryResult;
  const [openModalAdd, setOpenModal] = useState<boolean>(false);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);

  const { mutate } = useUpdate();
  const { mutate: deleteOne } = useDelete();
  const favoriteChange = (uuid: number, currentStatus: boolean) => {
    mutate({
      resource: 'calendar',
      id: uuid,
      values: { favorite: !currentStatus },
      successNotification: {
        description: t('calendar.update'),
        message: t('calendar.createText'),
        type: 'success',
      },
      errorNotification: {
        description: t('error.createText'),
        message: 'Возникла ошибка',
        type: 'error',
      },
    });
  };

  const items = (value: any): MenuProps['items'] => {
    return [
      {
        label: value.favorite
          ? 'Убрать из избранного '
          : 'Добавитиь в избранное',
        key: '1',
        disabled: !edit,
        onClick: () => {
          favoriteChange(value.uuid, value.favorite);
        },
        icon: <SignatureOutlined />,
      },
      {
        key: '2',
        type: 'divider',
      },
      {
        label: 'Редактировать',
        key: '3',
        onClick: () => {
          setSelectedUuid(value.uuid);
          setOpenModalEdit(true);
        },
        icon: <SignatureOutlined />,
      },

      {
        label: t('delete.label'),
        key: '4',
        icon: <DeleteOutlined />,
        danger: true,
        onClick: () => {
          deleteOne({
            resource: 'calendar',
            id: value.uuid,
          });
        },
      },
    ];
  };

  const menuProps = (value: any) => {
    const itms = items(value);

    return { items: itms };
  };

  const changeFavorite = () => {
    setFilters([
      { field: 'calendars.favorite', operator: 'eq', value: !showFavorites },
    ]);
    setShowFavorites(!showFavorites);
  };

  const { options: optionUser } = useSelect<IUser[]>({
    resource: 'users/get/userList',
    optionLabel: 'label',
    optionValue: 'value',
    liveMode: 'auto',
    hasPagination: false,
  });
  const options: any[] = optionUser;

  const [selectedUser, setSelectedUser] = useState<number>(me?.uuid || 0);

  const changeFilters = (values: any) => {
    const data = form.getFieldsValue();
    const filterData: CrudFilters = [];

    if (data.lender && data.lender.length < 3) {
      filterData.push({
        field: 'companies.orgName',
        operator: 'contains',
        value: '',
      });
    } else {
      filterData.push({
        field: 'companies.orgName',
        operator: 'contains',
        value: data.lender,
      });
    }
    if (data.contacts && data.contacts.length < 3) {
      filterData.push({
        field: 'companies.contact',
        operator: 'contains',
        value: '',
      });
    } else {
      filterData.push({
        field: 'companies.contact',
        operator: 'contains',
        value: data.contacts,
      });
    }

    setFilters(filterData);
  };

  return !tableProps.loading ? (
    <>
      {openModalAdd && (
        <ModalAddCalendar
          open={openModalAdd}
          onCancel={() => setOpenModal(false)}
          refetch={refetch}
          type={CalendarType.LOGISTIC}
          uuid={Number(selectedUuid)}
        />
      )}
      {openModalEdit && (
        <ModalEditCalendar
          open={openModalEdit}
          onCancel={() => setOpenModalEdit(false)}
          refetch={refetch}
          type={CalendarType.LOGISTIC}
          uuid={Number(selectedUuid)}
        />
      )}
      <List
        headerProps={{
          extra: (
            <Button
              onClick={() => setOpenModal(true)}
              type={'primary'}
              children={t('calendar.add')}
            />
          ),
        }}
      >
        <Space style={{ marginBottom: '12px' }} size={12}>
          <Button
            size="small"
            onClick={changeFavorite}
            type={!showFavorites ? 'primary' : 'dashed'}
            icon={!showFavorites ? <StarFilled /> : <StarOutlined />}
            children={
              !showFavorites ? 'Показать избранное' : 'Скрыть избранное'
            }
          />
          <Select
            showSearch
            style={{ width: '100%' }}
            defaultValue={me?.uuid}
            size="small"
            options={options}
            filterOption={(input, option) =>
              (option?.label ?? '')
                .toUpperCase()
                .includes(input.toLocaleUpperCase())
            }
            onChange={(value) => {
              setFilters([
                { field: 'user.uuid', operator: 'eq', value: value },
              ]);
              setSelectedUser(value);
            }}
          />
        </Space>
        <Form
          form={form}
          initialValues={{
            lender: '',
          }}
          layout="vertical"
        >
          <Row gutter={[6, 2]}>
            <Col xs={12} md={4}>
              <Form.Item name="lender">
                <Input
                  placeholder="Заказчик"
                  size="small"
                  onChange={changeFilters}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={4}>
              <Form.Item name="contacts">
                <Input
                  placeholder="Контакты"
                  size="small"
                  onChange={changeFilters}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={4}>
              <Form.Item>
                <Select
                  defaultValue={'asc'}
                  placeholder="Дата звонка"
                  onChange={(value: 'asc' | 'desc') =>
                    setSorter([{ field: 'calendars.dateCall', order: value }])
                  }
                  size="small"
                  options={[
                    { label: 'Сперва новые', value: 'desc' },
                    { label: 'Сперва пропущенные', value: 'asc' },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Table
          {...tableProps}
          rowKey="uuid"
          pagination={{
            ...tableProps.pagination,
            position: ['bottomRight'],
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '200'],
            size: 'small',
          }}
          // onChange={(value) => {
          //   if (value.pageSize) {
          //     localStorage.setItem('pageSizeOptions', String(value.pageSize));
          //     setPageSize(value.pageSize);
          //   }
          //   if (value.current) {
          //     setCurrent(value.current);
          //   }
          // }}
          scroll={{ x: 'max-content' }}
          size="small"
        >
          <Table.Column
            dataIndex="companies"
            key="companies.orgName"
            title={t('calendar.companies')}
            render={(companies, value: any) => (
              <Tag
                style={{ cursor: 'pointer' }}
                color={value.color ? value.color : '#0092fd'}
              >
                {companies.orgName}
              </Tag>
            )}
            // filterDropdown={(props: FilterDropdownProps) => (
            //   <FilterDropdown {...props}>
            //     <Input />
            //   </FilterDropdown>
            // )}
            // filterMode="tree"
            // filterSearch={true}
          />
          <Table.Column
            dataIndex="companies"
            key="companies"
            title={t('calendar.contact')}
            render={(value) => (
              <Space size={2} direction="vertical">
                {value.contact && JSON.parse(value.contact) ? (
                  JSON.parse(value.contact).map(
                    (contact: { name: string; phone: string }) => (
                      <Button
                        href={`viber://chat?number=%2B${
                          contact.phone ? contact.phone.replace('+', '') : ''
                        }`}
                        icon={<PhoneFilled />}
                        size="small"
                        type="link"
                      >
                        {contact.name} {contact.phone}
                      </Button>
                    ),
                  )
                ) : (
                  <></>
                )}
              </Space>
            )}
          />
          <Table.Column
            dataIndex="dateCall"
            key="calendars.dateCall"
            width={120}
            title={t('calendar.dateRecall')}
            render={(value) => (
              <TextField value={moment(value).format('DD.MM')} />
            )}
            // defaultSortOrder={getDefaultSortOrder('orgName', sorter)}
            // sorter
            // filterDropdown={(props: FilterDropdownProps) => (
            //   <FilterDropdown {...props}>
            //     <DatePicker disabled />
            //   </FilterDropdown>
            // )}
          />
          <Table.Column
            dataIndex="text"
            key="text"
            title={t('calendar.text')}
            width={300}
            render={(value) => <TextField value={value} />}
          />
          {!showFavorites && (
            <Table.Column
              dataIndex="status"
              key="status"
              align="right"
              title={t('calendar.status')}
              render={(_, value: any) => {
                const daysBetween = moment(
                  moment(value.dateCall).format('YYYY-MM-DD'),
                ).diff(moment().format('YYYY-MM-DD'), 'days');
                return (
                  <Tag
                    color={
                      daysBetween < 0
                        ? '#fd8600'
                        : daysBetween >= 0 && daysBetween < 1
                        ? '#e50000'
                        : '#0065ff'
                    }
                  >
                    {daysBetween < 0 ? (
                      <>
                        <ExclamationCircleOutlined />
                        <TextField
                          value={'Звонок пропущен'}
                          style={{ color: '#fff' }}
                        />
                      </>
                    ) : daysBetween >= 0 && daysBetween < 1 ? (
                      <>
                        <IssuesCloseOutlined />
                        <TextField
                          value={'Звонок сегодня'}
                          style={{ color: '#fff' }}
                        />
                      </>
                    ) : (
                      <>
                        <ClockCircleOutlined />
                        <TextField
                          value={`Звонок через ${daysBetween} (дней)`}
                          style={{ color: '#fff' }}
                        />
                      </>
                    )}
                  </Tag>
                );
              }}
            />
          )}
          {selectedUser === Number(me?.uuid) && (
            <Table.Column
              key="action"
              title=""
              width={20}
              render={(_, value: any) => (
                <>
                  <Dropdown
                    menu={menuProps(value)}
                    arrow
                    trigger={['click']}
                    placement="bottomLeft"
                    destroyPopupOnHide
                  >
                    <Button
                      icon={<EllipsisOutlined />}
                      shape="circle"
                      type="dashed"
                    />
                  </Dropdown>
                </>
              )}
            />
          )}
        </Table>
      </List>
    </>
  ) : (
    <Spin>Загрузка</Spin>
  );
};
